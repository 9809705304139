import React, { useState } from "react";
import { Space, List, Collapse, Flex, Tag, Row, Col } from 'antd';
import { EditButton, DeleteButton, ModalDisplay, LocationForm, MoreButton } from "../../../package";
import { useNavigate } from "react-router-dom";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const LocationItem = ({ editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleDelete = async () => {
        await mainApi.patch(`/location/delete/${item.uuid}`)
        refresh(true)
    };
    return (
        <>
            <List.Item style={{ padding: 0 }}>
                <Collapse
                    size="small"
                    type
                    ghost
                    items={[{
                        key: item.uuid,
                        label: (
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                                    <Flex justify="space-between">
                                        <Space>{item?.locationAccessible?.informations[0]?.title + " - " + item?.locationAccessible?.informations[0]?.subtitle}</Space>
                                    </Flex>
                                </Col>
                                <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                    <Flex justify="end">
                                        <Space>
                                            <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.locationStatus?.msg)}</Tag>
                                            <MoreButton text="mehr" onClick={() => navigate(`/location/${item?.uuid}`)} />
                                        </Space>
                                    </Flex>
                                </Col>
                            </Row>
                        ),
                        children: (<>
                            <div dangerouslySetInnerHTML={{ __html: item?.locationAccessible?.informations[0]?.text }} />
                            {!editable ? "" :
                                <Space>
                                    <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                    <DeleteButton handleDelete={() => handleDelete()} />
                                </Space>
                            }
                        </>)
                    }]}>
                </Collapse>

                <ModalDisplay
                    onCancel={() => setOpen(false)}
                    open={open}
                    content={<LocationForm
                        mainApi={mainApi}
                        uuid={item.uuid}
                        isSuccess={() => {
                            setOpen(false)
                            refresh(true)
                        }}
                    />} />
            </List.Item>
        </>
    )
};