import axios from "axios"

const APP_DEV = "false"
const apiKey = "N5UJDlpj5CofOu8UdUIA9oKre6oaxXb0qnKv3g05Oe2UKnKtSZy7YmE27qixKRaR";
const systemApiKey = "Zt47colIySIMBlyazLh5w3gAzspOTYlbOd3CRUTDpYNT644hakSpeOIC1XHnRZeq";
const customApiKey = "pPPW3KTNnZYLiDlCm9Qu75aL1Whd3N2h2rSBMBcflPCcVPF25AAKBuK6Mu1OOR1d";

export const AuthApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5502/auth/api/v1' : 'https://api.employee.coursesync.de/auth/api/v1',
    headers: {
        'apiKey': apiKey,
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});


export const AdminApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5502/admin/api/v1' : 'https://api.employee.coursesync.de/admin/api/v1',
    headers: {
        'apiKey': apiKey,
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const SystemApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5501/admin/api/v1' : 'https://api.system.coursesync.de/admin/api/v1',
    headers: {
        'apiKey': systemApiKey,
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});


export const CustomApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5500/admin/api/v1' : 'https://api.customer.coursesync.de/admin/api/v1',
    headers: {
        'apiKey': customApiKey,
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const SystemFileApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5501/admin/api/v1' : 'https://api.system.coursesync.de/admin/api/v1',
    headers: {
        'apiKey': systemApiKey,
        'Content-Type': 'multipart/form-data'
    },
    withCredentials: true,
});

export const frondend = { customBase: APP_DEV == "true" ? 'http://localhost:4020' : 'https://system.coursesync.de' }
