import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, DatePicker, Checkbox, Card, InputNumber } from 'antd';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { QuillEditor } from '../../..';
import { SelectWithData } from '../../../components/form/parts/SelectWithData';
import { ErrorDisplay } from '../../../components/displays/errorDisplay';

export const InformationForm = ({ title, isSuccess, accessibleId, uuid, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("");
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getCourseType();
        }
    }, []);


    const getCourseType = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/information/one/${accessibleId}`);
            const information = {
                title: response?.data?.information?.title,
                subtitle: response?.data?.information?.subtitle,
                text: response?.data?.information?.text,
                access: response?.data?.information?.access,
            }
            form.setFieldsValue(information);
        } catch (error) {
            setError("Fehler beim Laden der Daten: " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const setEditorValueInForm = (value) => {
        form.setFieldsValue({
            text: value,
        });
    };

    const onFinish = async (values) => {
        values.accessibleId = accessibleId;
        values.userId = user?.uuid;
        try {
            setLoading(true);
            if (uuid) {
                await mainApi.patch(`/information/update/${uuid}`, values);
            } else {
                await mainApi.post(`/information`, values);
            }
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ErrorDisplay error={error}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Form
                    name="InformationForm"
                    requiredMark={false}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical" a
                    validateTrigger="onChange"
                    style={{ width: "100%" }}
                >
                    <Space direction="vertical">
                        <h3>{uuid ? 'Information ändern' : 'Information erstellen'}</h3>
                    </Space>
                    <Spin spinning={false} size="large" loading={loading}>
                        <Row gutter={[16, 0]}>

                            {/**/}
                            <Col span={24}>
                                <Divider orientation='left' orientationMargin="0"><VisibilityOutlinedIcon /> Darstellung</Divider>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Kurses">
                                    <Form.Item
                                        hasFeedback
                                        name="title"
                                        rules={[{
                                            required: true,
                                            min: 1,
                                            message: 'Bitte einen Titel angeben!',
                                        },
                                        {
                                            max: 50,
                                            message: 'Der Title ist zu Lang!',
                                        },
                                        ]}>
                                        <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Untertitel des Kurses">
                                    <Form.Item
                                        hasFeedback
                                        name="subtitle"
                                        rules={[{
                                            required: true,
                                            min: 1,
                                            message: 'Bitte einen Untertitel angeben!',
                                        },
                                        {
                                            max: 100,
                                            message: 'Der Untertitel ist zu Lang!',
                                        },
                                        ]}>
                                        <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Untertitel" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Kurses">
                                    <Form.Item
                                        hasFeedback
                                        name="text"
                                    >
                                        <QuillEditor placeholder={"Information"} defaultValue={form.getFieldValue("text")} onChange={setEditorValueInForm} />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                                <SelectWithData
                                    label="Status"
                                    tooltip={"Wie die Kursart im System angezeigt werden?"}
                                    name="access"
                                    data={[
                                        { value: "public", label: "Öffentlich (Trainer)" },
                                        { value: "intern", label: "Intern" },
                                    ]}
                                />

                            </Col>
                            <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                                <Form.Item >
                                    <Button type="primary" htmlType="submit" loading={loading} block>
                                        {title ? title : uuid ? "Speichern" : 'Erstellen'}                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </div >
        </ErrorDisplay>
    );
};