import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export function QuillEditor({ placeholder, defaultValue, onChange }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    setContent(defaultValue);
  }, [defaultValue]);

  const handleContentChange = (value) => {
    setContent(value);

    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Card size='small'>
      <h6>{placeholder}</h6>
      <ReactQuill
        style={{ maxHeight: 500, overflow: "auto"}}
        theme="snow"
        value={content}
        onChange={handleContentChange}
      />
    </Card>
  );
};