import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Form, Input, Button, Row, Col, Card, Space, Spin, message } from 'antd';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { ErrorDisplay } from '../../..';



export const LogInForm = ({ linkPwForgotten, mainApi, empApi, custApi }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogin = async (values) => {
        try {
            setLoading(true);

            const response = await mainApi.post(`/login`, values);
            const emp = await empApi.post(`/login`, values);
            const cust = await custApi.post(`/login`, values);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("manager", JSON.stringify(response.data.manager));
            console.log("YEY")
            navigate('/dashboard');

        } catch (error) {
            message.error(`Anmeldung Fehlgeschlagen: ${error?.response?.data?.message}`);
        } finally {
            setLoading(false);
        }
    };


    return (
        <ErrorDisplay loading={loading}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Form
                    name="normal_login"
                    className="login-form"
                    requiredMark={false}
                    initialValues={{ remember: true, }}
                    onFinish={handleLogin}
                    layout="vertical"
                    validateTrigger="onChange"
                    style={{ width: "100%" }}

                >
                    <Card title={
                        <Space direction="vertical">
                            <h3>Anmeldung Serviceportal</h3>
                            <h6>Swimgood-Schwimmschule - CourseSyncS</h6>
                        </Space>
                    } >
                        <Spin spinning={loading} size="large">
                            <Form.Item
                                hasFeedback
                                label="Benutzername:"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte Benutzername eingeben',
                                    },
                                    {
                                        type: 'email',
                                        message: "Bitte eine gültige E-Mail-Adesse eingeben"
                                    },
                                ]} >
                                <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Benutzername" />
                            </Form.Item>
                            <Form.Item
                                hasFeedback
                                name="password"
                                label="Passwort:"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Bitte das Passwort eingeben!',
                                    },
                                ]}
                            >
                                <Input.Password prefix={<LockOutlinedIcon fontSize='small' />} placeholder="Passwort"
                                />
                            </Form.Item>
                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox >Angemeldet bleiben</Checkbox>
                                </Form.Item>
                                {linkPwForgotten ?
                                    <a className="login-form-forgot" href="">
                                        Passwort vergessen?
                                    </a>
                                    : ''}
                            </Form.Item>

                            <Form.Item>
                                <Row align="middle" justify="space-between">
                                    <Col>
                                        <Button type="primary" htmlType="submit" className="text-start btn-secondary" >
                                            Anmelden
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Spin>
                    </Card>
                </Form>
            </div>
        </ErrorDisplay>
    );
};