import React, { useState } from "react";
import { Space, List, Collapse, Flex } from 'antd';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import { getAccess } from "../helpers";
import { EditButton, DeleteButton, ModalDisplay, StatForm } from "../../../../package";

export const StatItem = ({ editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/stat/delete/${item.uuid}`)
        refresh(true)
    };
    return (
        <>
            <List.Item style={{ padding: 0 }}>
                <Collapse
                    size="small"
                    type
                    ghost
                    items={[{
                        key: item.uuid,
                        label: (
                            <Flex justify="space-between">
                                <Space>{getIcon(item?.label)}{item?.subtitle + " - " + item?.title}</Space>
                                {getAccess(item?.access)}
                            </Flex>
                        ),
                        children: (<>
                            <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                            {!editable ? "" :
                                <Space>
                                    <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                    <DeleteButton handleDelete={() => handleDelete()} />
                                </Space>
                            }
                        </>)
                    }]}>
                </Collapse>

                <ModalDisplay
                    onCancel={() => setOpen(false)}
                    open={open}
                    content={<StatForm
                        mainApi={mainApi}
                        accessibleId={item.accessibleId}
                        uuid={item.uuid}
                        isSuccess={() => {
                            setOpen(false)
                            refresh(true)
                        }}
                    />} />
            </List.Item>
        </>
    )
};

function getIcon(label) {
    switch (label) {
        case "positive":
            return (<AddCircleOutlinedIcon style={{ color: "green" }} />);
        case "negative":
            return (<RemoveCircleOutlineOutlinedIcon style={{ color: "red" }} />);
        case "neutral":
            return (<InfoOutlinedIcon style={{ color: "blue" }} />);
    }
}