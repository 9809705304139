import React, { useState } from "react";
import { StatForm, DescriptionForm, StepDisplay, ImageForm, LocationForm } from "../..";
//Icons
import EditLocationAltOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined';

export const FullLocationForm = ({ isSuccess, mainApi, fileApi }) => {
    const [location, setLocation] = useState({});
    const [icon, setIcon] = useState({});
    const [stat, setStat] = useState({});
    const [image, setImage] = useState({});
    const [description, setDescription] = useState({});
    const [current, setCurrent] = useState(0);
    const next = () => {
        if (current + 1 === items.length) {
            isSuccess()
        } else {
            setCurrent(current + 1);
        }
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const state = () => { return (current + 1 === items.length) };

    const items = [
        {
            key: 1,
            title: 'Profil',
            icon: <EditLocationAltOutlinedIcon />,
            content: (<LocationForm
                mainApi={mainApi}
                uuid={location && location.uuid}
                title={state ? "Weiter" : "Fertig"}
                isSuccess={(location) => {
                    setLocation(location);
                    next();
                }}
            />),
        },
        {
            key: 2,
            title: 'Icon',
            content: (<ImageForm
                mainApi={mainApi}
                fileApi={fileApi}
                uuid={icon && icon.uuid}
                accessibleId={location.accessibleId}
                title={state ? "Weiter" : "Fertig"}
                text="Icon"
                type={"icon"}
                isSuccess={(image) => {
                    setIcon(image)
                    next()
                }}
            />),
            icon: <InsertEmoticonOutlinedIcon />,
            skip: true,
        },
        {
            key: 3,
            title: 'Bild',
            content: (<ImageForm
                mainApi={mainApi}
                fileApi={fileApi}
                uuid={image && image.uuid}
                accessibleId={location.accessibleId}
                title={state ? "Weiter" : "Fertig"}
                type={"main"}
                isSuccess={(image) => {
                    setImage(image)
                    next()
                }}
            />),
            icon: <ImageOutlinedIcon />,
            skip: true,
        },
        {
            key: 4,
            title: 'Darstellung',
            content: (<DescriptionForm
                mainApi={mainApi}
                uuid={description && description.uuid}
                accessibleId={location.accessibleId}
                title={state ? "Weiter" : "Fertig"}
                isSuccess={(description) => {
                    setDescription(description)
                    next()
                }}
            />),
            icon: <DescriptionOutlinedIcon />,
            skip: true,
        },
        {
            key: 5,
            title: 'Asset',
            content: (<StatForm
                mainApi={mainApi}
                uuid={stat && stat.uuid}
                accessibleId={location.accessibleId}
                title={state ? "Weiter" : "Fertig"}
                isSuccess={(stat) => {
                    setStat(stat)
                    isSuccess()
                }}
            />),
            icon: <ExposureOutlinedIcon />,
            skip: true,
        },
    ];
    return (<StepDisplay items={items} prev={() => prev()} next={() => next()} current={current} />);
};