import React, { useEffect, useState } from "react";
import { Flex, Tag, Space, List, Collapse, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton, ModalDisplay, MoreButton, UnitData, ContactForm, ContactData, AvailableList, CommunicationList, CourseForm, ErrorDisplay } from "../..";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { CustomApi } from "../../../urlConfig";

export const CourseItem = ({ ghost, editable, item, refresh, mainApi, custApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [amountPar, setAmountPar] = useState();
    const [error, setError] = useState();


    const getData = async () => {
        try {
            setLoading(true);
            const par = await custApi.get(`/participationCount?courseIds=${item?.uuid}&status=ACTIVE`);
            setAmountPar(par?.data.participation);
        } catch (error) {
            setError(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getData();
    }, [open, item]);

    const handleDelete = async () => {
        await mainApi.patch(`/course/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item>
            <ErrorDisplay error={error} loading={loading}>
                <Row gutter={[8, 16]} justify="space-between">
                    <Col flex>
                        {item?.courseType?.courseTypeAccessible?.informations[0]?.subtitle + " - " + item?.identifier + " - " + item?.coach?.name}
                    </Col>
                    <Col flex>
                        {`Teinehmer: ${amountPar || "null"} / ${item?.courseType?.places} (${(amountPar || null) / item?.courseType?.places * 100}%)`}
                    </Col>
                    <Col flex>
                        <Space>
                            <DeleteButton handleDelete={() => handleDelete()} />
                            <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.courseStatus?.msg)}</Tag>
                            <MoreButton text="mehr" onClick={() => navigate(`/course/${item?.uuid}`)} />
                        </Space>
                    </Col>
                </Row>
                <ModalDisplay
                    onCancel={() => setOpen(false)}
                    open={open}
                    content={<CourseForm
                        mainApi={mainApi}
                        uuid={item.uuid}
                        isSuccess={() => {
                            setOpen(false)
                            refresh(true)
                        }}
                    />} />
            </ErrorDisplay>
        </List.Item>
    )
};

