// Sidebar.js
import React from "react";
import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import { EmptyDisplay } from "../../package";
//Icons
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';


export const EmployeeNavigationPage = ({ layout }) => {
  const navigate = useNavigate();
  const headerData = {
    title: 'Mitarbeiter',
    icon: <GroupOutlinedIcon />,
  }

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <EmptyDisplay
              onClick={() => navigate('/coach')}
              icon={<SportsOutlinedIcon />}
              card
              text={<h3>Trainer</h3>}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <EmptyDisplay
              onClick={() => navigate('/user')}
              icon={<PersonOutlineOutlinedIcon />}
              card
              text={<h3>User</h3>}
            />
          </Col>
          <Col xs={24} sm={24} md={8} lg={8} xl={8}>
            <EmptyDisplay
              onClick={() => navigate('/employee')}
              icon={<GroupOutlinedIcon />}
              card
              text={<h3>Mitarbeiter</h3>}
            />
          </Col>
        </Row>
      </Layout>
    </React.Fragment >
  );
};