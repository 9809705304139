import React, { useState } from "react";
import { Card, Space } from 'antd';
import { ImageForm } from "./ImageForm";
import { getAccess, getImageType } from '../helpers';
import { DeleteButton, EditButton, ModalDisplay } from "../../../../package";

export const ImageItem = ({ editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/image/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <>
            <div key={item.uuid}>
                <Card
                    size="small"
                    cover={<img
                        style={{
                            height: '300px',
                            objectFit: 'contain',
                        }}
                        alt={item.title} src={`data:image/jpeg;base64,${item.data}`} />}
                    headStyle={{ background: "lightgray" }}
                    extra={<>
                        {getImageType(item?.type)}
                        {getAccess(item?.access)}
                        {!editable ? "" : <Space>
                            <EditButton onClick={() => setOpen(true)} />
                            <DeleteButton handleDelete={() => handleDelete()} />
                        </Space>}
                    </>}
                    title={<Space><h6>{item?.title} - {item?.subtitle}</h6></Space>}>
                </Card>
            </div>
            <ModalDisplay
                onCancel={() => { setOpen(false) }}
                open={open}
                content={
                    <ImageForm
                        mainApi={mainApi}
                        uuid={item?.uuid}
                        accessibleId={item.accessibleId}
                        isSuccess={() => {
                            setOpen(false)
                            refresh(true)
                        }}
                    />}
            />
        </>
    );
};