// Sidebar.js
import React, { useState, useEffect } from "react";
import { Button, Card, Col, Divider, Flex, Row, Space } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
//MyCourseIcons
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CustomApi, SystemApi } from "../../urlConfig";
import { CourseData, CourseForm, CourseParticipantList, EditButton, ErrorDisplay, ModalDisplay, ParticipationCourseList, ParticipationList, SimpleCourseDateList } from "../../package";

export const OneCoursePage = ({ layout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { uuid } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  const [participants, setParticipants] = useState({});
  const headerData = {
    title: `${data.courseType?.courseTypeAccessible?.informations[0]?.title + " - " + data?.identifier}`,
    icon: <DisplaySettingsOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager.god)
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await SystemApi.get(`/course/one/${uuid}`);
      setData(response.data.course);
      const participants = await CustomApi.get(`/participation/all?limit=100&courseIds=${uuid}&status=${"ACTIVE"}`);
      setParticipants(participants.data.participation);
    } catch (error) {
      setError(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTokenAndFetchData();
    getData();
  }, [open, refresh]);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay error={error} loading={loading}>
          <Row gutter={[16, 16]} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Flex justify="space-between">
                <Button type="primary" size="small" icon={<ArrowBackOutlinedIcon fontSize="small" />} onClick={() => navigate("/course")} >Zurück</Button>
                {!edit ? "" : <EditButton text="Kurs Bearbeiten" onClick={() => setOpen(true)} />}
              </Flex>
              <Divider style={{ margin: 10 }}></Divider>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <CourseData card data={data} amountOfParticipants={participants.length} />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card size="small">
                    <SimpleCourseDateList dates={data.courseDates} bordered editable />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card>
                    <ParticipationCourseList courseIds={uuid} mainApi={SystemApi} custApi={CustomApi} />
                  </Card>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Card>
                    <ParticipationCourseList courseIds={uuid} mainApi={SystemApi} custApi={CustomApi} filterStatus={["FREE_CANCELED", "FEE_CANCELED", "FULL_CANCELED",]} />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalDisplay
            onCancel={() => { setOpen(false) }}
            open={open}
            content={
              <CourseForm
                mainApi={SystemApi}
                uuid={data?.uuid}
                isSuccess={() => {
                  setOpen(false)
                  setRefresh(true)
                }}
              />}
          />
        </ErrorDisplay>
      </Layout >
    </React.Fragment >
  );
};