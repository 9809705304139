import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { CollapsibleProvider } from './utils/CollapsibleProvider';
import {  ConfigProvider } from 'antd';


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider theme={{
        token: {
          colorPrimary: "#1a4568",
          colorInfo: "#1a4568",
        },
      }}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);