// Sidebar.js
import React, { useState, useEffect } from "react";
import { Col, Row, Spin } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
//MyCourseIcons
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { AdminApi, SystemApi, SystemFileApi } from "../../urlConfig";
import { AdressDisplay, AccessibleDisplay, ErrorDisplay, LocationList, UnitList, ContactList, CommunicationList, AwardList, CourseTypeList, UserList, EmployeeList, CourseForm, CourseList } from "../../package";

export default function OneLocationPage({ layout }) {
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [refresh, setIsRefresh] = useState(false);


  const [user, setUser] = useState({});
  const [data, setData] = useState({});
  const headerData = {
    title: "Standort",
    icon: <LocationOnOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    setError("")
    const localUser = JSON.parse(localStorage.getItem("user"))
    setUser(localUser)
    setLoading(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await SystemApi.get(`/location/one/${uuid}`);
      setData(response.data.location);
    } catch (error) {
      setError("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTokenAndFetchData();
    const fetchData = async () => {
      await getData();
    };
    //fetchData();
  }, [refresh]);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <Spin spinning={loading}></Spin>
        <ErrorDisplay loading={loading} error={error}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
              <CourseList editable mainApi={SystemApi} />
              <EmployeeList editable mainApi={SystemApi} empApi={AdminApi} />
              <UnitList editable mainApi={SystemApi} locationId={uuid} />
              <LocationList mainApi={SystemApi} fileApi={SystemFileApi} />
              <UserList editable empApi={AdminApi} />
              <CourseTypeList mainApi={SystemApi} />
              <AwardList editable mainApi={SystemApi} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
              <AccessibleDisplay
                mainApi={SystemApi}
                fileApi={SystemFileApi}
                editable
                description image document stat
                accessibleId={data.accessibleId} />
            </Col>
          </Row>
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};