import React from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card, Space } from 'antd';
//Icons
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

export const CustomerData = ({ ghost, data, card }) => {
    const items = [
        {
            key: '1',
            label: (<> <HomeOutlinedIcon fontSize="small" /> Adresse: </>),
            children: (<Space direction="vertical">
                {data?.adress?.street || <Skeleton paragraph={{ rows: 0 }} />}
                {data?.adress?.postalCode + " " + data?.adress?.city || <Skeleton paragraph={{ rows: 0 }} />}
                {data?.adress?.country || <Skeleton paragraph={{ rows: 0 }} />}
            </Space>),
            span: 24,
        },
        {
            key: '2',
            label: (<a href={`mailto:${data.email}`}> <EmailOutlinedIcon fontSize="small" /> Email:</a>),
            children: (<>{data?.email || <Skeleton paragraph={{ rows: 0 }} />}</ >),
            span: 24,
        },
        {
            key: '3',
            label: (<a href={`tel:${data.phone}`}> <LocalPhoneOutlinedIcon fontSize="small" /> Telefon: </a>),
            children: (<>{data?.phone?.phoneNumber || <Skeleton paragraph={{ rows: 0 }} />}</>),
            span: 24,
        },
    ]

    if (card) {
        return (
            <Card size="small" >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        {ghost ? "" : <Divider orientation='left' orientationMargin="0"><AlternateEmailOutlinedIcon /> Kontakt: </Divider>}
                        <Descriptions size="small" bordered layout="vertical" items={items} />
                    </Col>
                </Row>
            </Card>
        );
    }
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0"><AlternateEmailOutlinedIcon /> Kontakt: </Divider>}
                    <Descriptions size="small" bordered layout="vertical" items={items} />
                </Col>
            </Row>
        </>

    );
};