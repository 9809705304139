import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Space, Card, Row, Col, Divider, Table, Tag, Button, Popconfirm, message, InputNumber } from 'antd';
import { CreateButton, ErrorDisplay, SearchFilter, ListDisplay, ModalDisplay, UnitItem, UnitForm, getFilter, ContactItem, ContactForm, CoachItem, CoachForm, CustomerForm, CustomerItem, ParticipationForm, ParticipationItemParticipant, ParticipationItemCourse, MoreButton } from "../..";
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';

export const BillList = ({ card, custApi, pagination, filterStartDate, filterEndDate, filterStatus, filterText }) => {
    const [data, setData] = useState([]);
    const [fastMode, setFastMode] = useState(false);
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const navigate = useNavigate();

    const getData = async (search = getFilter(filterText), status = getFilter(filterStatus), start = getFilter(filterStartDate), end = getFilter(filterEndDate),) => {
        try {
            const response = await custApi.get(`/bill/all?page=${currentPage}&limit=${pageSize}&search=${search}&status=${status}&startDate=${start}&endDate=${end}`);
            setData(response.data);
        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const toggleFastMode = () => {
        setFastMode(!fastMode)
    };

    const handleSubmit = async (data) => {
        const values = { paidAmount: data.amount }
        console.log(data)
        setLoading(true);
        try {
            await custApi.patch(`/bill/update/${data.uuid}`, values);
            message.success(`Rechung eingetragen`);
            setRefresh(true)
        } catch (error) {
            message.error(`Fehlgeschlagen: ${(error?.response?.data?.message)}`);
        } finally {
            setLoading(false);
        }
    };


    const handlePageSizeChange = (size) => {
        setCurrentPage(1);
        setPageSize(size);
        setRefresh(true);
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData()
        setAmount(0)
        setRefresh(false)
    }, [open, refresh]);

    const columns = [
        !fastMode ?
            { title: 'Nr.', dataIndex: 'index', rowScope: 'row', align: "center", width: 1, render: (text) => <Tag color="gray">{text}</Tag> }
            : { title: 'Aktion', dataIndex: 'data', rowScope: 'row', align: "center", width: 1, render: (text) => <Button onClick={() => handleSubmit(text)} size="small" type="primary">eintragen</Button> },
        { title: 'Nummer', dataIndex: 'number', key: 'number', render: (text) => <a>{text}</a> },
        { title: 'Kunde', dataIndex: 'name', key: 'name', render: (text) => <a>{text}</a> },
        { title: 'Erstellungs Datum', dataIndex: 'date', key: 'date' },
        {
            title: 'Erhalten', dataIndex: 'data', key: 'paidAmount', width: 3, render: (text) => !fastMode ? text.paidAmount + " €" :
                < Space ><InputNumber onChange={(e) => setAmount(e)} value={parseFloat(text.paidAmount)} size="small"></InputNumber>
                    <Button onClick={() => handleSubmit({ uuid: text.uuid, amount: amount })} icon={<CreditScoreOutlinedIcon fontSize="small" />} size="small"></Button>
                </Space >
        },
        { title: 'Betrag', dataIndex: 'amount', key: 'amount', width: 3 },
        { title: 'Status', dataIndex: 'status', render: (text) => getDisplay(text) },
        { title: 'Link', dataIndex: 'uuid', width: 2, render: (text) => <MoreButton text="Kurs" onClick={() => navigate(`participation/${text}`)}></MoreButton> },
    ];

    const entry = data?.bill?.map((data, index) => ({
        name: data?.participation?.participant?.customer?.person?.name?.firstname + " " + data?.participation?.participant?.customer?.person?.name?.lastname,
        index: index + 1 + ".",
        number: data?.number,
        data: data,
        date: new Date(data?.date).toLocaleDateString("de-DE", { day: "2-digit", month: "2-digit", year: "numeric" }),
        amount: data?.amount + " €",
        paidAmount: data,
        status: data?.billStatus?.statusValue,
        uuid: data?.participationId
    }));

    const result = (
        <Row Row gutter={[16, 16]} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                <ErrorDisplay error={error} loading={loading}>
                    <SearchFilter
                        text status range
                        onSubmit={(values) => { getData(values.search, values.status) }}
                    />
                    <Table
                        style={{ overflowX: "auto" }}
                        title={() => <Row justify={"space-between"}>
                            <Col>
                                <PoolOutlinedIcon />Rechungen
                            </Col>
                            <Col>
                                <Button onClick={() => { toggleFastMode() }} size="small">ändern</Button>
                            </Col>
                        </Row>}
                        size="small"
                        columns={columns}
                        dataSource={entry} />
                </ErrorDisplay>
            </Col >
        </Row >
    );



    return (<>
        {card ? <Card size="small" loading={loading} > {result} </Card>
            :
            result}
    </>);
};


function getDisplay(status) {
    switch (status) {
        case "OPEN":
            return (<Tag color="blue">offen</Tag>)
        case "PAID":
            return (<Tag color="green">bezahlt</Tag>)
        case "DELAYED":
        case "REMIND":
            return (<Tag color="orange">verspätet</Tag>)
        case "WARNED":
        case "DUNNING":
        case "LAST_DUNNING":
            return (<Tag color="red">Mahnung</Tag>)
        case "DROPPED":
            return (<Tag color="lightgray">storniert</Tag>)

    }
}