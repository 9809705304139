// Sidebar.js
import React, { useState, useEffect } from "react";
import { Button, Col, Divider, Flex, Row } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
//MyCourseIcons
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CustomApi, SystemApi } from "../../urlConfig";
import { CoachData, CoachForm, CourseList, EditButton, ErrorDisplay, ModalDisplay } from "../../package";

export const OneCoachPage = ({ layout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { employeeId } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  const headerData = {
    title: `${error ? error : data?.name}`,
    icon: <SportsOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager?.god)
    setLoading(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await SystemApi.get(`/coach/one/${employeeId}`);
      if (!response.data.coach) { setError("404") }
      setData(response.data.coach);
    } catch (error) {
      setError(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTokenAndFetchData();
    const fetchData = async () => {
      await getData();
    };
    fetchData();
  }, [open, refresh]);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay error={error} loading={loading}>
          <Row gutter={[16, 16]} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Flex justify="space-between">
                <Button type="primary" size="small" icon={<ArrowBackOutlinedIcon fontSize="small" />} onClick={() => navigate("/coach")} >Zurück</Button>
                {!edit ? "" : <EditButton text="Trainer Bearbeiten" onClick={() => setOpen(true)} />}
              </Flex>
              <Divider style={{ margin: 10 }}></Divider>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CoachData card mainApi={SystemApi} employeeId={data?.employeeId} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <CourseList custApi={CustomApi} mainApi={SystemApi} coachIds={data.uuid}/>
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalDisplay
            onCancel={() => { setOpen(false) }}
            open={open}
            content={
              <CoachForm
                mainApi={SystemApi}
                uuid={data?.employeeId}
                isSuccess={() => {
                  setOpen(false)
                  setRefresh(true)
                }}
              />}
          />
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};