import React, { useState } from "react";
import { Flex, List, Space } from 'antd';
import { ModalDisplay } from "../../../components/displays/ModalDisplay";
import { getAccess } from "../helpers";
import { EditButton } from "../../../components/buttons/editButton";
import { DeleteButton } from "../../../components/buttons/deleteButton";
import { DocumentForm } from "./DocumentForm";
import { DownloadButton } from "./downloadButton";
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

export const DocumentItem = ({ editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/document/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <>
            <List.Item>
                <Flex justify="space-between">
                    <Space><ArticleOutlinedIcon />{item?.title + " - " + item?.subtitle}</Space>
                    <Space>
                        {getAccess(item?.access)}
                        <DownloadButton mainApi={mainApi} text={"Laden"} item={item} />
                        {!editable ? "" :
                            <Space>
                                <EditButton onClick={() => setOpen(true)} />
                                <DeleteButton handleDelete={() => handleDelete()} />
                            </Space>
                        }
                    </Space>
                </Flex>
                <ModalDisplay
                    onCancel={() => setOpen(false)}
                    open={open}
                    content={<DocumentForm
                        mainApi={mainApi}
                        accessibleId={item.accessibleId}
                        uuid={item.uuid}
                        isSuccess={() => {
                            setOpen(false)
                            refresh(true)
                        }}
                    />} />
            </List.Item >
        </>
    )
};