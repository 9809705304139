import React, { useState, useEffect } from "react";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Descriptions, Card, Button, Skeleton, Spin, Modal, Steps, Divider, Row, Col } from 'antd';

const InitPage = ({ user, showModel }) => {

    const [current, setCurrent] = useState(0);
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const items = [
        {
            key: 1,
            title: 'Profil',
            icon: <ExclamationCircleOutlined />,
        },
        {
            title: 'Second',
            content: 'Second-content',
            icon: <ExclamationCircleOutlined />,
        },
        {
            title: 'Last',
            content: 'Last-content',
        },
    ];

    return (
        <>
            <Steps current={current} items={items} responsive={false} />
            <Divider orientation="left">{current > 0 && (
                <Button
                    block
                    type="text"
                    onClick={() => prev()}
                >
                    Zurück
                </Button>
            )}</Divider>
            <Row gutter={[16, 0]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    {items[current].content}
                    {current === 0 && (""
                    )}

                </Col>
            </Row>
        </>

    );
};

export default InitPage;