import React from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card } from 'antd';
//Icons
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import EuroOutlinedIcon from '@mui/icons-material/EuroOutlined';

export const UnitData = ({ ghost, data, card }) => {

    const items = [
        {
            key: '1',
            label: (<> <AspectRatioOutlinedIcon fontSize="small" /> Größe: </>),
            children: (<>
                {data?.places + (data?.places === 1 ? " Kurs" : " Kurse") || <Skeleton paragraph={{ rows: 0 }} />}
            </>),
            span: 2,
        },
        {
            key: '2',
            label: (<> <EuroOutlinedIcon fontSize="small" /> Kosten/Std: </>),
            children: (<>
                {data?.price + " € pro Std." || <Skeleton paragraph={{ rows: 0 }} />}
            </>),
            span: 2,
        },
    ]

    if (card) {
        return (
            <Card
                size="small"
                title={ghost ? "" : <><MeetingRoomOutlinedIcon /> Daten: </>}
                headStyle={{ backgroundColor: '#9dcedf' }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Descriptions size="small" bordered layout="vertical" items={items} />
                    </Col>
                </Row>
            </Card>

        );
    }
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0"><MeetingRoomOutlinedIcon /> Daten: </Divider>}
                    <Descriptions size="small" bordered layout="vertical" items={items} />
                </Col>
            </Row>
        </>

    );
};