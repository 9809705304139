import React from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card, Space } from 'antd';
//Icons
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';

export const ParticipantData = ({ ghost, data, card }) => {

    const items = [
        {
            key: '1',
            label: (<> <CakeOutlinedIcon fontSize="small" /> Geburtsdatum: </>),
            children: (<Space direction="vertical">
                {new Date(data?.person?.birthdate).toLocaleDateString() || <Skeleton paragraph={{ rows: 0 }} />}
            </Space>),
            span: 24,
        },
        {
            key: '2',
            label: (<> <TransgenderOutlinedIcon fontSize="small" /> Geschlecht: </>),
            children: (<Space direction="vertical">
                {data?.person?.sex === "male" ? "Mänlich " : data?.person?.sex === "female" ? "Weiblich " : "Andere "}
            </Space>),
            span: 24,
        },
        {
            key: '3',
            label: (<> <LocalHospitalOutlinedIcon fontSize="small" /> Gesundheitsinformationen: </>),
            children: (<Space direction="vertical">
                {data?.health || "Keine Daten angegeben"}
            </Space>),
            span: 24,
        },
    ]

    if (card) {
        return (
            <Card size="small">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        {ghost ? "" : <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Daten: </Divider>}
                        <Descriptions size="small" bordered layout="vertical" items={items} />
                    </Col>
                </Row>
            </Card>

        );
    }
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Daten: </Divider>}
                    <Descriptions size="small" bordered layout="vertical" items={items} />
                </Col>
            </Row>
        </>

    );
};