import React, { useEffect, useState } from "react";
import { Space, Row, Col, Divider, Card } from 'antd';
import { getFilter, ModalDisplay, ErrorDisplay, CreateButton, LocationItem, ListDisplay, SearchFilter, FullLocationForm } from "../../../package";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export const LocationList = ({ ghost, editable, locations, mainApi, fileApi, card, pagination, filterText, filterStartDate, filterEndDate, filterStatus }) => {
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getData = async (search = "", status = getFilter(filterStatus), start = getFilter(filterStartDate), end = getFilter(filterEndDate),) => {
        try {
            const response = await mainApi.get(`/location/all?locationsIds=${locations}&page=${currentPage}&limit=${pageSize}&search=${search}&startDate=${start}&endDate=${end}&status=${status}&sortBy=createdAt`);
            setData(response.data)
        } catch (error) {
            setError("Probleme beim Laden des Moduls: " + error?.response?.data?.message && error?.message)
        } finally {
            setLoading(false)
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true);
    };
    const handlePageSizeChange = (size) => {
        setCurrentPage(1);
        setPageSize(size);
        setRefresh(true);
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData();
        setRefresh(false)
    }, [open, refresh]);

    return (
        <Card
            title={!card ? "" : <> <LocationOnOutlinedIcon /> Standorte </>}
            headStyle={{ backgroundColor: '#9dcedf' }}
            extra={!card ? "" : !editable ? "" : <CreateButton size="middle" text="Hinzufügen" onClick={() => setOpen(true)} />}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                        <Space>
                            <><LocationOnOutlinedIcon />Standorte</>
                            {!editable ? "" : <CreateButton text="Hinzufügen" onClick={() => setOpen(true)} />}
                        </Space>
                    </Divider>}
                    <SearchFilter
                        text={!filterText} range={!filterStartDate && !filterEndDate} status={!filterStatus}
                        onSubmit={(values) => { getData(values.search, values.status, values.timeRange && values.timeRange[0], values.timeRange && values.timeRange[1]) }}></SearchFilter>
                    <ErrorDisplay error={error} loading={loading}>
                        <ListDisplay
                            emptyText="Kein Standort gefunden. Einen neuen anlegen?"
                            emptyOnClick={() => setOpen(true)}
                            data={data.location}
                            listItem={(item) => <LocationItem editable={editable} mainApi={mainApi} item={item} refresh={() => setRefresh(true)} />}
                            maxHeight={550}
                            pagination={pagination ? "" : {
                                current: currentPage,
                                total: data?.totalPages,
                                onChange: handlePageChange,
                            }}
                            pageSize={{ current: pageSize, handle: handlePageSizeChange }}
                        />
                        <ModalDisplay
                            onCancel={() => { setOpen(false) }}
                            open={open}
                            content={
                                <FullLocationForm
                                    mainApi={mainApi}
                                    fileApi={fileApi}
                                    isSuccess={() => {
                                        setOpen(false)
                                        setRefresh(true)
                                    }}
                                />}
                        />
                    </ErrorDisplay>
                </Col >
            </Row >
        </Card>
    );
};