import React, { useState, useEffect } from "react";
import { Space, Card, Row, Col, Divider } from 'antd';
import { CreateButton, ErrorDisplay, SearchFilter, ListDisplay, ModalDisplay, UnitItem, UnitForm, getFilter, ContactItem, ContactForm, AvailableItem, AvailableForm } from "../..";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';

export const AvailableList = ({ ghost, contactId, editable, card, mainApi, pagination, filterText, filterStartDate, filterEndDate, filterStatus }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);

    const getData = async () => {
        try {
            const response = await mainApi.get(`/available/all?page=${currentPage}&limit=${pageSize}&contactIds=${contactId}`);
            setData(response.data);
        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true);
    };
    const handlePageSizeChange = (size) => {
        setCurrentPage(1);
        setPageSize(size);
        setRefresh(true);
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData()
        setRefresh(false)
    }, [open, refresh]);

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                    <Space>
                        <><EventAvailableOutlinedIcon />Erreichbarkeit</>
                        {!editable ? "" : <CreateButton text="Hinzufügen" onClick={() => setOpen(true)} />}
                    </Space>
                </Divider>}
                <ErrorDisplay error={error} loading={loading}>
                    <ListDisplay
                        emptyText="Keine Zeiten Eingetragen."
                        emptyOnClick={() => setOpen(editable ? true : false)}
                        data={data.available}
                        listItem={(item) => <AvailableItem editable={editable} mainApi={mainApi} item={item} refresh={() => setRefresh(true)} />}
                        //maxHeight={500}
                        pagination={pagination ? "" : {
                            current: currentPage,
                            total: data?.totalPages,
                            onChange: handlePageChange,
                        }}
                        pageSize={{ current: pageSize, handle: handlePageSizeChange }}
                    />
                    <ModalDisplay
                        onCancel={() => { setOpen(false) }}
                        open={open}
                        content={
                            <AvailableForm
                                mainApi={mainApi}
                                contactId={contactId}
                                isSuccess={() => {
                                    setOpen(false)
                                    setRefresh(true)
                                }}
                            />}
                    />
                </ErrorDisplay>
            </Col >
        </Row >
    );
};