import { Button, message } from "antd";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
export const DownloadButton = ({ item, text, size, mainApi }) => {
    const downloadDocument = async () => {
        try {
            const response = await mainApi.get(`/document/download/${item?.uuid}`, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${item.title}_${item.subtitle}.pdf`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            message.error('Fehler beim Herunterladen des Dokuments:' + error?.response?.data?.message && error?.message);
        }
    };

    return (
        <Button size={size ? size : "small"} type='primary' icon={<DownloadOutlinedIcon fontSize="small" />} onClick={downloadDocument}>{text}</Button>
    )
};