import React, { useState } from "react";
import { Flex, Tag, Space, List, Collapse, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton, ModalDisplay, MoreButton, UnitData, ContactForm, ContactData, AvailableList, CommunicationList, CustomerData } from "../..";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const CustomerItem = ({ ghost, editable, item, refresh, custApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await custApi.patch(`/contact/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item style={{ padding: 0 }}>
            <Collapse
                size="small"
                type
                ghost
                items={[{
                    key: item.uuid,
                    label: (
                        <Row gutter={[8, 16]} justify="space-between">
                            <Col flex>
                                {item?.person?.sex === "male" ? "Herr " : item?.person?.sex === "female" ? "Frau " : "Person "}
                                {item?.person?.name?.firstname + " " + item?.person?.name?.lastname}
                            </Col>
                            <Col flex>
                                <Space>
                                    {/* <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.contactStatus?.msg)}</Tag> */}
                                    <MoreButton text="mehr" onClick={() => navigate(`/customer/${item?.uuid}`)} />
                                </Space>
                            </Col>
                        </Row>
                    ),
                    children: (<>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <CustomerData data={item} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            </Col>
                            {/* {!editable ? "" : <Col flex>
                                <Space>
                                    <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                     <DeleteButton handleDelete={() => handleDelete()} /> 
                                     <Space>
                            </Col>} */}
                        </Row>

                    </>)
                }]}>
            </Collapse >
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<ContactForm
                    custApi={custApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item >
    )
};