import React, { useEffect, useState } from 'react';
import { Form, Upload, Button, message, Space, Spin, Row, Col, Divider, Tooltip, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { SelectWithData } from '../../../../package';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';

export const ImageForm = ({ text, title, isSuccess, accessibleId, uuid, type, mainApi, fileApi }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState([])
    const [image, setImage] = useState({})

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getImage();
        }
    }, []);

    const getImage = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/image/one/${uuid}`);
            setImage(response?.data?.image)
            const image = {
                title: response?.data?.image?.title,
                subtitle: response?.data?.image?.subtitle,
                type: response?.data?.image?.type,
                access: response?.data?.image?.access,
            }
            form.setFieldsValue(image);
        } catch (error) {
            message.error('Fehler beim Laden der Daten: ' + error?.response?.data?.message && error?.message);
            isSuccess()
        } finally {
            setLoading(false);
        }
    };


    const onFinish = async (values) => {
        let response;
        if (type) { values.type = type }
        values.accessibleId = accessibleId;
        values.userId = user?.uuid;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/image/update/${uuid}`, values);
            } else {
                const formData = new FormData();
                formData.append('title', values.title);
                formData.append('subtitle', values.subtitle);
                formData.append('type', values.type);
                formData.append('accessibleId', values.accessibleId);
                formData.append('userId', values.userId);
                formData.append('image', values.image[0].originFileObj);

                response = await fileApi.post(`/image`, formData);
            }
            isSuccess(response?.data?.image);
        } catch (error) {
            message.error('Fehler beim Hochladen des Bildes' + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    const beforeUpload = (file) => {
        const isImage = file.type.startsWith('image/');
        if (!isImage) {
            message.error('Du kannst nur Bilddateien hochladen!');
        }
        return false;
    };

    return (
        <Form
            name="image-form"
            requiredMark={false}
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onChange"
            style={{ width: "100%" }}
        >
            <Space direction="vertical">
                <h3>{text ? text : "Bild"}{uuid ? ' ändern' : ' hochladen'}</h3>
            </Space>
            <Spin spinning={false} size="large" loading={loading}>
                <Row gutter={[16, 0]}>
                    {!uuid ? "" : <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <img
                            style={{
                                height: '300px',
                                objectFit: 'contain',
                            }}
                            alt={image.title} src={`data:image/jpeg;base64,${image.data}`} />
                    </Col>}
                    {/**/}
                    <Col span={24}>
                        <Divider orientation='left' orientationMargin="0"><UploadOutlined /> Informationen</Divider>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Bildes">
                            <Form.Item
                                hasFeedback
                                name="title"
                                rules={[{
                                    required: true,
                                    min: 1,
                                    message: 'Bitte einen Titel angeben!',
                                },
                                {
                                    max: 50,
                                    message: 'Der Title ist zu Lang!',
                                },
                                ]}>
                                <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Untertitel des Bildes">
                            <Form.Item
                                hasFeedback
                                name="subtitle"
                                rules={[{
                                    required: true,
                                    min: 1,
                                    message: 'Bitte einen Untertitel angeben!',
                                },
                                {
                                    max: 100,
                                    message: 'Der Untertitel ist zu Lang!',
                                },
                                ]}>
                                <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Untertitel" />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    {uuid ? "" :
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item
                                name="image"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                rules={[{ required: true, message: 'Bitte lade ein Bild hoch!' }]}
                            >
                                <Upload
                                    name="image"
                                    beforeUpload={beforeUpload}
                                    maxCount={1}
                                    accept='image/png,image/jpeg,image/jpg'
                                    listType="picture"
                                >
                                    <Button icon={<UploadOutlined />} loading={loading}>
                                        Bild hochladen
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>}
                    {type ? "" :
                        <Col xs={24} sm={12} md={8} lg={9} xl={9} >
                            <SelectWithData
                                label="Typ"
                                tooltip={"Wie soll das Bild im System angezeigt werden?"}
                                name="type"
                                data={[
                                    { value: "profile", label: "Profilbild" },
                                    { value: "icon", label: "Icon" },
                                    { value: "main", label: "Hauptbild" },
                                    { value: "other", label: "Weiteres Bild" },
                                ]}
                            />
                        </Col>
                    }
                    <Col xs={24} sm={12} md={type ? 16 : 8} lg={type ? 18 : 9} xl={type ? 18 : 9} >
                        <SelectWithData
                            label="Status"
                            tooltip={"Wie soll das Bild im System angezeigt werden?"}
                            name="access"
                            data={[
                                { value: "public", label: "Öffentlich" },
                                { value: "intern", label: "Intern" },
                            ]}
                        />

                    </Col>
                    <Col xs={8} sm={type ? 0 : 12} md={0} lg={0} xl={0} ></Col>
                    <Col xs={16} sm={12} md={8} lg={6} xl={6} >
                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={loading} block>
                                {title ? title : uuid ? "Speichern" : 'Erstellen'}                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );
};