import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Checkbox, Select, DatePicker } from 'antd';
import { SelectWithData } from '../..'

import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

export const EmployeeContactForm = ({ title, isSuccess, uuid, empApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await empApi.get(`/employee/one/${uuid}`);
            const data = {
                status: response?.data?.employee?.employeeStatus?.statusValue,
                phoneNumber: response?.data?.employee?.phone?.phoneNumber,
                email: response?.data?.employee?.email,

                street: spiltStreet(response?.data?.employee?.adress?.street, 0),
                streetNumber: spiltStreet(response?.data?.employee?.adress?.street, 1),
                postalCode: response?.data?.employee?.adress?.postalCode,
                city: response?.data?.employee?.adress?.city,
                country: response?.data?.employee?.adress?.country,

            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {
        const data = {
            email: values.email,
            status: values.status,
            phoneNumber: values.phoneNumber,
            adress: {
                street: values.street + " " + values.streetNumber,
                postalCode: values.postalCode,
                city: values.city,
                country: values.country,
            },
        }
        try {
            setLoading(true);
            await empApi.patch(`/employee/update/${uuid}`, data);
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="employeeContactForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{"Kontakt"}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0">< ContactMailOutlinedIcon /> Kontaktdaten</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="E-Mail-Adresse">
                                <Form.Item
                                    hasFeedback
                                    name="email"
                                    rules={[{
                                        required: true,
                                        type: 'email',
                                        message: 'Bitte eine gültige E-Mail-Adresse eingeben',
                                    },
                                    {
                                        max: 255,
                                        message: 'Die E-Mail-Adresse ist zu lang!',
                                    },
                                    ]}>
                                    <Input prefix={<AlternateEmailOutlinedIcon fontSize='small' />} placeholder="E-Mail-Adresse" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Telefonnummer">
                                <Form.Item
                                    hasFeedback
                                    name="phoneNumber"
                                    rules={[{
                                        required: true,
                                        message: 'Bitte Ihre Telefonnummer eingeben!',
                                    },
                                    {
                                        pattern: /^(0|\+)[0-9]+$/,
                                        message: 'Die Telefonnummer ist ungültig! Bitte im Format: +123456789 angeben',
                                    },
                                    ]}>
                                    <Input prefix={<LocalPhoneOutlinedIcon fontSize='small' />} placeholder="Telefon" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={16} sm={18} md={18} lg={18} xl={18} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Straße">
                                <Form.Item
                                    hasFeedback
                                    name="street"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte eine Straße angeben!',
                                    },
                                    {
                                        max: 200,
                                        message: 'Die Straße ist zu lang!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ \-.,]+$/i,
                                        message: 'Bitte ein gültiges Format eingeben!'
                                    }
                                    ]}>
                                    <Input prefix={<HomeOutlinedIcon fontSize='small' />} placeholder="Straße" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={8} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item
                                hasFeedback
                                name="streetNumber"
                                rules={[{
                                    required: true,
                                    message: 'Bitte eine Hausnummer angeben!',
                                },
                                {
                                    pattern: /^[a-zA-Z0-9äöüßÄÖÜ \-.,]+$/i,
                                    message: 'Bitte ein gültiges Format eingeben!'
                                },
                                ]}>
                                <Input prefix={<NumbersOutlinedIcon fontSize='small' />} placeholder="Nr." />
                            </Form.Item>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item
                                hasFeedback
                                name="postalCode"
                                rules={[{
                                    required: true,
                                    message: 'Bitte die PLZ eingeben',
                                },
                                {
                                    pattern: /^\d{5}$/,
                                    message: 'Bitte ein gültiges Format eingeben! [12345]'
                                }
                                ]}>
                                <Input prefix={<LocationOnOutlinedIcon fontSize='small' />} placeholder="PLZ" />
                            </Form.Item>
                        </Col>
                        <Col xs={16} sm={18} md={18} lg={18} xl={18} >
                            <Form.Item
                                hasFeedback
                                name="city"
                                rules={[{
                                    required: true,
                                    min: 2,
                                    message: 'Bitte die Stadt eingeben!',
                                },
                                {
                                    pattern: /^[a-zA-ZäöüßÄÖÜ \-]+$/i,
                                    max: 100,
                                    message: 'Die Stadt ist ungültig!',
                                },
                                ]}>
                                <Input prefix={<LocationCityOutlinedIcon fontSize='small' />} placeholder="Stadt" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item
                                hasFeedback
                                name="country"
                                rules={[{
                                    required: true,
                                    min: 2,
                                    message: 'Bitte ein Land eingeben!',
                                },
                                {
                                    pattern: /^[a-zA-ZäöüßÄÖÜ \-]+$/i,
                                    max: 100,
                                    message: 'Das Land ist ungültig!',
                                },
                                ]}>
                                <Input prefix={<LocationCityOutlinedIcon fontSize='small' />} placeholder="Land" />
                            </Form.Item>
                        </Col>
                        <Col xs={12} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Wie die Kursart im System angezeigt werden?"}
                                name="status"
                                data={[
                                    { value: "INACTIVE", label: "Inaktiv" },
                                    { value: "ACTIVE", label: "Aktiv" },
                                    { value: "PENDING", label: "Nur Ansicht" },
                                    { value: "BLOCKED", label: "Blockiert" },
                                ]}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};

function spiltStreet(string, part) {
    const lastIndex = string.lastIndexOf(" ");
    if (part === 0) {
        return string.slice(0, lastIndex);
    }
    return string.slice(lastIndex + 1);
}