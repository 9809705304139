import React, { useState } from "react";
import { Space, List, Collapse, Flex, Tag } from 'antd';
import { EditButton, DeleteButton, ModalDisplay, AwardForm, MoreButton } from "../..";
import { useNavigate } from "react-router-dom";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const AwardItem = ({ editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleDelete = async () => {
        await mainApi.patch(`/award/delete/${item.uuid}`)
        refresh(true)
    };
    return (
        <>
            <List.Item style={{ padding: 0 }}>
                <Collapse
                    size="small"
                    type
                    ghost
                    items={[{
                        key: item.uuid,
                        label: (
                            <Flex justify="space-between">
                                <Space>{item?.awardAccessible?.informations[0]?.title + " - " + item?.awardAccessible?.informations[0]?.subtitle}</Space>
                                <Space>
                                    <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.awardStatus?.msg)}</Tag>
                                    <MoreButton text="mehr" onClick={() => navigate(`/award/${item?.uuid}`)} />
                                </Space>
                            </Flex>
                        ),
                        children: (<>
                            <div dangerouslySetInnerHTML={{ __html: item?.awardAccessible?.informations[0]?.text }} />
                            {!editable ? "" :
                                <Space>
                                    <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                    <DeleteButton handleDelete={() => handleDelete()} />
                                </Space>
                            }
                        </>)
                    }]}>
                </Collapse>

                <ModalDisplay
                    onCancel={() => setOpen(false)}
                    open={open}
                    content={<AwardForm
                        mainApi={mainApi}
                        uuid={item.uuid}
                        isSuccess={() => {
                            setOpen(false)
                            refresh(true)
                        }}
                    />} />
            </List.Item>
        </>
    )
};