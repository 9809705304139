import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip } from 'antd';
import { QuillEditor, SelectWithData } from '../../../package';
//Icons
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { FileUnknownFilled } from '@ant-design/icons';

export const LocationForm = ({ title, isSuccess, uuid, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])


    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getLocation();
        }
    }, []);


    const getLocation = async () => {
        let response;
        try {
            setLoading(true);
            response = await mainApi.get(`/location/one/${uuid}`);
            const location = {
                title: response?.data?.location?.locationAccessible?.informations[0]?.title,
                subtitle: response?.data?.location?.locationAccessible?.informations[0]?.subtitle,
                text: response?.data?.location?.locationAccessible?.informations[0]?.text,
                status: response?.data?.location?.locationStatus?.statusValue,
                street: spiltStreet(response?.data?.location?.adress?.street, 0),
                streetNumber: spiltStreet(response?.data?.location?.adress?.street, 1),
                postalCode: response?.data?.location?.adress?.postalCode,
                city: response?.data?.location?.adress?.city,
                country: response?.data?.location?.adress?.country,
            }
            form.setFieldsValue(location);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
            message.error(JSON.stringify(response));
            isSuccess()
        } finally {
            setLoading(false);
        }
    };

    const setEditorValueInForm = (value) => {
        form.setFieldsValue({
            text: value,
        });
    };

    const onFinish = async (values) => {
        let response;
        values.userId = user?.uuid;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/location/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/location`, values);
            }
            isSuccess(response?.data?.location);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="LocationForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Standort bearbeiten' : 'Standort erstellen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><VisibilityOutlinedIcon /> Darstellung</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Standorts">
                                <Form.Item
                                    hasFeedback
                                    name="title"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Titel angeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Title ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Kürzel des Standorts">
                                <Form.Item
                                    hasFeedback
                                    name="subtitle"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Kürzel angeben!',
                                    },
                                    {
                                        max: 100,
                                        message: 'Der Kürzel ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Kürzel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Standorts">
                                <Form.Item
                                    hasFeedback
                                    name="text"
                                >
                                    <QuillEditor placeholder={"Standortbeschreibung"} defaultValue={form.getFieldValue("text")} onChange={setEditorValueInForm} />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={16} sm={18} md={18} lg={18} xl={18} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Rechnungsadresse">
                                <Form.Item
                                    hasFeedback
                                    name="street"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte eine Straße angeben!',
                                    },
                                    {
                                        max: 200,
                                        message: 'Die Straße ist zu lang!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ \-.,]+$/i,
                                        message: 'Bitte ein gültiges Format eingeben!'
                                    }
                                    ]}>
                                    <Input prefix={<HomeOutlinedIcon fontSize='small' />} placeholder="Straße" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={8} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item
                                hasFeedback
                                name="streetNumber"
                                rules={[{
                                    required: true,
                                    message: 'Bitte eine Hausnummer angeben!',
                                },
                                {
                                    pattern: /^[a-zA-Z0-9äöüßÄÖÜ \-.,]+$/i,
                                    message: 'Bitte ein gültiges Format eingeben!'
                                },
                                ]}>
                                <Input prefix={<NumbersOutlinedIcon fontSize='small' />} placeholder="Nr." />
                            </Form.Item>
                        </Col>
                        <Col xs={8} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item
                                hasFeedback
                                name="postalCode"
                                rules={[{
                                    required: true,
                                    message: 'Bitte die PLZ eingeben',
                                },
                                {
                                    pattern: /^\d{5}$/,
                                    message: 'Bitte ein gültiges Format eingeben! [12345]'
                                }
                                ]}>
                                <Input prefix={<LocationOnOutlinedIcon fontSize='small' />} placeholder="PLZ" />
                            </Form.Item>
                        </Col>
                        <Col xs={16} sm={18} md={18} lg={18} xl={18} >
                            <Form.Item
                                hasFeedback
                                name="city"
                                rules={[{
                                    required: true,
                                    min: 2,
                                    message: 'Bitte die Stadt eingeben!',
                                },
                                {
                                    pattern: /^[a-zA-ZäöüßÄÖÜ \-]+$/i,
                                    max: 100,
                                    message: 'Die Stadt ist ungültig!',
                                },
                                ]}>
                                <Input prefix={<LocationCityOutlinedIcon fontSize='small' />} placeholder="Stadt" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item
                                hasFeedback
                                name="country"
                                rules={[{
                                    required: true,
                                    min: 2,
                                    message: 'Bitte ein Land eingeben!',
                                },
                                {
                                    pattern: /^[a-zA-ZäöüßÄÖÜ \-]+$/i,
                                    max: 100,
                                    message: 'Das Land ist ungültig!',
                                },
                                ]}>
                                <Input prefix={<LocationCityOutlinedIcon fontSize='small' />} placeholder="Land" />
                            </Form.Item>
                        </Col>


                        <Col xs={12} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Wie die Kursart im System angezeigt werden?"}
                                name="status"
                                data={[
                                    { value: "ACTIVE", label: "Aktiv" },
                                    { value: "INTERNAL", label: "Intern (Verwaltung)" },
                                    { value: "SOON", label: "Intern (Trainer)" },
                                ]}
                            />

                        </Col>
                        <Col xs={12} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};

function spiltStreet(string, part) {
    const lastIndex = string.lastIndexOf(" ");
    if (part === 0) {
        return string.slice(0, lastIndex);
    }
    return string.slice(lastIndex + 1);
}