import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Button, Spin, Space, message, Row, Col, Divider, Tooltip, DatePicker, Checkbox, Card, InputNumber } from 'antd';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { QuillEditor, SelectWithData } from '../../../../package';

export const DescriptionForm = ({ title, isSuccess, accessibleId, uuid, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getDescription();
        }
    }, []);


    const getDescription = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/description/one/${accessibleId}`);
            const description = {
                title: response?.data?.description?.title,
                subtitle: response?.data?.description?.subtitle,
                text: response?.data?.description?.text,
                short: response?.data?.description?.short,
                access: response?.data?.description?.access,
            }
            form.setFieldsValue(description);
        } catch (error) {
            message.error(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
        } finally {
            setLoading(false);
        }
    };

    const setEditorValueInForm = (value) => {
        form.setFieldsValue({
            text: value,
        });
    };

    const onFinish = async (values) => {
        values.accessibleId = accessibleId;
        values.userId = user?.uuid;
        try {
            setLoading(true);
            if (uuid) {
                await mainApi.patch(`/description/update/${uuid}`, values);
            } else {
                await mainApi.post(`/description`, values);
            }
            isSuccess();
        } catch (error) {
            message.error(`Fehlgeschlagen: ${(error?.response?.data?.message)}`);
            message.error(`Fehlgeschlagen: ${((error))}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="DescriptionFrom"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical" a
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Kundenbeschreibung ändern' : 'Kundenbeschreibung erstellen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><VisibilityOutlinedIcon /> Darstellung</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="title"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Titel angeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Title ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Kürzel des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="short"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Kürzel angeben!',
                                    },
                                    {
                                        max: 100,
                                        message: 'Der Kürzel ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Kürzel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Untertitel des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="subtitle"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Untertitel angeben!',
                                    },
                                    {
                                        max: 100,
                                        message: 'Der Untertitel ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Untertitel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="text"
                                >
                                    <QuillEditor placeholder={"Beschreibung"} defaultValue={form.getFieldValue("text")} onChange={setEditorValueInForm} />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Wie die Kursart im System angezeigt werden?"}
                                name="access"
                                data={[
                                    { value: "public", label: "Öffentlich" },
                                    { value: "intern", label: "Intern" },
                                ]}
                            />

                        </Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};