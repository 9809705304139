import { Select, Form, Tooltip, Col, message, Space } from 'antd';
import { useEffect, useState } from 'react';

export const CoachSelect = ({ disabled, mainApi, filterStatus = "", tooltip = "Trainer Wählen", label = "Trainer", name = "coachId" }) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)


    const getData = async () => {
        try {
            const response = await mainApi.get(`/coach/all?status=${filterStatus}`);
            setData(response.data.coach)
        } catch (error) {
            setError(true)
            message.error("Probleme beim Laden des Moduls: " + error?.response?.data?.message)
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title={tooltip}>
            <Form.Item
                hasFeedback
                name={name}
                rules={[
                    {
                        required: true,
                        message: `Bitte den ${label} wählen`,
                    },
                ]}
            >
                <Select
                    disabled={error || disabled}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    style={{ width: '100%' }}
                    placeholder={"Trainer"}
                    options={
                        data?.map((value) => ({
                            value: value?.uuid,
                            label: value?.name
                        }))
                    }
                >
                </Select>
            </Form.Item>
        </Tooltip >
    );
};