import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Card, Space, Empty } from 'antd';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { getAccess } from "../helpers";
import { DeleteButton, DescriptionForm, EditButton, CreateButton, ModalDisplay, ErrorDisplay, EmptyDisplay } from "../../../../package";

export const DescriptionDisplay = ({ ghost, editable, accessibleId, mainApi }) => {
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [description, setDescription] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const handleDelete = async (uuid) => {
        await mainApi.patch(`/description/delete/${uuid}`)
        setRefresh(true)
    };

    const getData = async () => {
        try {
            const response = await mainApi.get(`/description/one/${accessibleId}`);
            setDescription(response.data.description);
        } catch (error) {
            setError("Probleme beim Laden des Moduls: " + error?.response?.data?.message)
        } finally {
            setLoading(false)

        }
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData();
        setRefresh(false)
    }, [open, refresh]);

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                    <Space>
                        <>
                            <DescriptionOutlinedIcon />
                            Kundenbeschreibung
                        </>
                        {editable ?
                            !description ?
                                <CreateButton text="erstellen" onClick={() => setOpen(true)} /> :
                                <>
                                    <EditButton onClick={() => setOpen(true)} />
                                    <DeleteButton handleDelete={() => handleDelete(description?.uuid)} />
                                </>
                            : ("")
                        }
                    </Space>
                </Divider>}
                <ErrorDisplay error={error} loading={loading}>
                    {!description ?
                        <EmptyDisplay iconHeight={0} card text={"Keine Beschreibung Vorhanden"} onClick={() => editable ? setOpen(true) : setOpen(false)} />
                        :
                        <Card
                            headStyle={{ background: "lightgray" }}
                            extra={getAccess(description?.access)}
                            size="small"
                            title={<h6>{description?.title}</h6>}>
                            <div style={{ color: "gray" }}>{description?.short + " - " + description?.subtitle}</div>
                            {<div dangerouslySetInnerHTML={{ __html: description?.text }} />}
                        </Card >}
                    <ModalDisplay
                        onCancel={() => setOpen(false)}
                        open={open}
                        content={
                            <DescriptionForm
                                mainApi={mainApi}
                                accessibleId={accessibleId}
                                uuid={description?.uuid}
                                isSuccess={() => { setOpen(false) }}
                            />
                        } />
                </ErrorDisplay>
            </Col>
        </Row>
    );
};