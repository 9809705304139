import React from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card, Space, Button, Flex } from 'antd';
//Icons
import MeetingRoomOutlinedIcon from '@mui/icons-material/MeetingRoomOutlined';
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { json, useNavigate } from "react-router-dom";
import { MoreButton } from "../../components/buttons/moreButton";
import { CourseTypeData } from "../courseType/CourseTypeData";
export const CourseData = ({ ghost, data, card, amountOfParticipants }) => {
    const navigate = useNavigate()

    const items = [
        {
            key: '1',
            label: (<> <AssignmentIndOutlinedIcon fontSize="small" /> Art: </>),
            children: (
                <Flex justify="space-between">
                    {data?.courseType?.courseTypeAccessible?.informations[0]?.title || <Skeleton paragraph={{ rows: 0 }} />}
                    <MoreButton onClick={() => navigate(`/courseType/${data?.courseType?.uuid}`)} text="mehr"></MoreButton>
                </Flex>),
            span: 24,
        },
        {
            key: '2',
            label: (<> <EmailOutlinedIcon fontSize="small" /> Trainer:</>),
            children: (
                <Flex justify="space-between">
                    {data?.coach?.name || <Skeleton paragraph={{ rows: 0 }} />}
                    <MoreButton onClick={() => navigate(`/coach/${data?.coach?.employeeId}`)} text="mehr"></MoreButton>
                </Flex>),
            span: 24,
        },
        {
            key: '3',
            label: (<> <EmailOutlinedIcon fontSize="small" /> Kurspreis:</>),
            children: (
                <Flex justify="space-between">
                    {data?.price + " €" || <Skeleton paragraph={{ rows: 0 }} />}
                </Flex>),
            span: 24,
        },
        {
            key: '4',
            label: (<> <EmailOutlinedIcon fontSize="small" /> Auslastung:</>),
            children: (
                <Flex justify="space-between">
                    {`Teinehmer: ${amountOfParticipants || "0"} / ${data?.courseType?.places} (${(amountOfParticipants || 0) / data?.courseType?.places * 100}%)`}
                </Flex>),
            span: 24,
        },
        {
            key: '5',
            label: (<> <EmailOutlinedIcon fontSize="small" /> Anmeldezeitraum:</>),
            children: (
                <Flex justify="space-between">
                    {new Date(data?.release?.start).toLocaleString() + " - " + new Date(data?.release?.end).toLocaleString() || <Skeleton paragraph={{ rows: 0 }} />}
                </Flex>),
            span: 24,
        },
        {
            key: '6',
            label: (<> <EmailOutlinedIcon fontSize="small" /> Frühanmeldung:</>),
            children: (
                <Flex justify="space-between">
                    {new Date(data?.prerelease?.start).toLocaleString() + " - " + new Date(data?.prerelease?.end).toLocaleString() || <Skeleton paragraph={{ rows: 0 }} />}
                </Flex>),
            span: 24,
        },

    ]

    if (card) {
        return (
            <Card size="small">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        {ghost ? "" : <Divider orientation='left' orientationMargin="0"><AlternateEmailOutlinedIcon /> Daten: </Divider>}
                        <Descriptions size="small" bordered layout="vertical" items={items} />
                    </Col>
                </Row>
            </Card>

        );
    }
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0"><AlternateEmailOutlinedIcon /> Daten: </Divider>}
                    <Descriptions size="small" bordered layout="vertical" items={items} />
                </Col>
            </Row>
        </>

    );
};