import { Button, Popconfirm } from "antd";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

export const DeleteButton = ({ handleDelete, text, size, title = "Löschen?" }) => {
    return (
        <Popconfirm
            title={title}
            icon={<DeleteForeverOutlinedIcon fontSize="small" style={{ color: 'red' }} />}
            placement="bottomLeft"
            onConfirm={() => { }}
            onCancel={handleDelete}
            okText="Nein"
            cancelButtonProps={{ danger: 'true' }}
            cancelText="Ja">
            <Button size={size ? size : "small"} style={{ color: "red" }} type='default' icon={<DeleteForeverOutlinedIcon fontSize="small" />}>{text}</Button>
        </Popconfirm>
    )
};