import React, { useState, useEffect } from "react";
import { Space, Card, Row, Col, Divider } from 'antd';
import { getFilter, CourseTypeForm, CreateButton, ErrorDisplay, SearchFilter, ListDisplay, CourseTypeItem, ModalDisplay, UserItem, UserForm } from "../..";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';


export const UserList = ({ ghost, editable, card, empApi, pagination, filterText, filterStatus, filterStartDate, filterEndDate }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getData = async (search = "", status = getFilter(filterStatus), start = getFilter(filterStartDate), end = getFilter(filterEndDate),) => {
        try {
            const response = await empApi.get(`/user/all?page=${currentPage}&limit=${pageSize}&search=${search}&status=${status}&startDate=${start}&endDate=${end}`);
            setData(response.data);
        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true);
    };
    const handlePageSizeChange = (size) => {
        setCurrentPage(1);
        setPageSize(size);
        setRefresh(true);
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData();
        setRefresh(false)
    }, [open, refresh]);

    return (
        <Card
            title={!card ? "" : <> <AccountCircleOutlinedIcon /> User </>}
            headStyle={{ backgroundColor: '#9dcedf' }}
            extra={!card ? "" : !editable ? "" : <CreateButton size="middle" text="Hinzufügen" onClick={() => setOpen(true)} />}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                        <Space>
                            <><AccountCircleOutlinedIcon />User</>
                            {!editable ? "" : <CreateButton text="Hinzufügen" onClick={() => setOpen(true)} />}
                        </Space>
                    </Divider>}
                    <SearchFilter
                        text={!filterText} status={!filterStatus} range={!filterStartDate || !filterEndDate}
                        onSubmit={(values) => { getData(values.search, values.status, values?.range ? values?.range[0] : "", values?.range ? values?.range[1] : "") }}
                    />
                    <ErrorDisplay error={error} loading={loading}>
                        <ListDisplay
                            emptyText="Keine User gefunden. Einen neuen anlegen?"
                            emptyOnClick={() => setOpen(editable ? true : false)}
                            data={data.user}
                            listItem={(item) => <UserItem editable={editable} empApi={empApi} item={item} refresh={() => setRefresh(true)} />}
                            maxHeight={550}
                            pagination={pagination ? "" : {
                                current: currentPage,
                                total: data?.totalPages,
                                onChange: handlePageChange,
                            }}
                            pageSize={{ current: pageSize, handle: handlePageSizeChange }}
                        />
                        <ModalDisplay
                            onCancel={() => { setOpen(false) }}
                            open={open}
                            content={
                                <UserForm
                                    empApi={empApi}
                                    isSuccess={() => {
                                        setOpen(false)
                                        setRefresh(true)
                                    }}
                                />}
                        />
                    </ErrorDisplay>
                </Col >
            </Row >
        </Card>
    );
};