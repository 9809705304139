import React, { useState } from "react";
import { Flex, Tag, Space, List, Collapse, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { CourseTypeForm, EditButton, DeleteButton, ModalDisplay, MoreButton, CourseTypeData, InformationTextData, UserData, UserForm } from "../..";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const UserItem = ({ editable, item, refresh, empApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await empApi.patch(`/user/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item style={{ padding: 0 }}>
            <Collapse
                size="small"
                type
                ghost
                items={[{
                    key: item.uuid,
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                                <Flex justify="space-between">
                                    <Space>{item?.userName + " - " + item?.email}</Space>
                                </Flex>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <Flex justify="end">
                                    <Space>
                                        <Tag color="gray">{new Date(item?.createdAt).toLocaleDateString()}</Tag>
                                        <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.userStatus?.msg)}</Tag>
                                    </Space>
                                </Flex>
                            </Col>
                        </Row>
                    ),
                    children: (<>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <UserData data={item} />
                            </Col>
                            {!editable ? "" : <Col flex>
                                <Space>
                                    <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                    {/* <DeleteButton handleDelete={() => handleDelete()} /> */}
                                </Space>
                            </Col>}
                        </Row>
                    </>)
                }]}>
            </Collapse>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<UserForm
                    empApi={empApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item>
    )
};

