import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import ImageSearchOutlinedIcon from '@mui/icons-material/ImageSearchOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import FilterTiltShiftOutlinedIcon from '@mui/icons-material/FilterTiltShiftOutlined';
import { Tag, Tooltip } from 'antd';


export function getAccess(text) {
    switch (text) {
        case "intern":
            return (<Tag icon={<AutorenewOutlinedIcon />} color="yellow"> Intern</Tag>);
        case "public":
            return (<Tag icon={<AutorenewOutlinedIcon />} color="green"> Öffentlich</Tag>);
    }
}

export function getImageType(text) {
    switch (text) {
        case "profile":
            return (<Tag icon={<Tooltip trigger={'hover'} arrow={false} title="Profilbild"><AccountCircleOutlinedIcon /></Tooltip>} color="blue"></Tag>);
        case "icon":
            return (<Tag icon={<Tooltip trigger={'hover'} arrow={false} title="Icon"><FilterTiltShiftOutlinedIcon /></Tooltip>} ></Tag>);
        case "main":
            return (<Tag icon={<Tooltip trigger={'hover'} arrow={false} title="Hauptbild"><ImageOutlinedIcon /></Tooltip>} color="green"></Tag>);
        case "other":
            return (<Tag icon={<Tooltip trigger={'hover'} arrow={false} title="Zusatzbild"><ImageSearchOutlinedIcon /></Tooltip>} color="green"></Tag>);
    }
}