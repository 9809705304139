import React, { useState } from "react";
import { Space, List, Collapse, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { ModalDisplay, MoreButton, ParticipantForm } from "../..";

export const ParticipantItem = ({ ghost, editable, item, refresh, custApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await custApi.patch(`/contact/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item style={{ padding: 0 }}>
            <Collapse
                size="small"
                type
                ghost
                items={[{
                    key: item.uuid,
                    label: (
                        <Row gutter={[8, 16]} justify="space-between">
                            <Col flex>
                                {item?.person?.name?.firstname + " " + item?.person?.name?.lastname}

                                {item?.person?.sex === "male" ? " (M) " : item?.person?.sex === "female" ? " (W)" : " (D)"}
                            </Col>
                            <Col flex>
                                {new Date(item?.person?.birthdate).toLocaleDateString()}
                            </Col>
                            <Col flex>
                                <Space>
                                    {/* <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.contactStatus?.msg)}</Tag> */}
                                    <MoreButton text="mehr" onClick={() => navigate(`/participant/${item?.uuid}`)} />
                                </Space>
                            </Col>
                        </Row>
                    ),
                    children: (<>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                {item?.health || "Keine Gesundheitsinformationen angegeben"}
                            </Col>
                        </Row>

                    </>)
                }]}>
            </Collapse >
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<ParticipantForm
                    custApi={custApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item >
    )
};