// Sidebar.js
import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import Layout from "../../layout/Layout";
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import { ErrorDisplay, LocationList } from "../../package";
import { SystemApi, SystemFileApi } from "../../urlConfig";

export const LocationPage = ({ layout }) => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const headerData = {
    title: 'Standorte',
    icon: <DisplaySettingsOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager.god)
    setLoading(false);
  };

  useEffect(() => {
    checkTokenAndFetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay loading={loading}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
              <LocationList editable={edit} mainApi={SystemApi} fileApi={SystemFileApi}></LocationList>
            </Col>
          </Row>
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};