// Sidebar.js
import React, { useState, useEffect } from "react";
import { Card, Modal } from "antd";
import Header from "../../layout/Header";
import { useNavigate } from "react-router-dom";
import Layout from "../../layout/Layout";
import InitPage from '../start/InitPage';
//MyCourseIcons
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import { AuthApi } from "../../urlConfig";
import { ErrorDisplay } from "../../package";

export default function Dashboard({ layout }) {
  const [loading, setLoading] = useState(true);
  const [init, setInit] = useState(false);
  const [error, setError] = useState();

  const [user, setUser] = useState({});
  const headerData = {
    title: 'Startseite',
    icon: <AnalyticsOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const localUser = JSON.parse(localStorage.getItem("user"))
    setUser(localUser)
    if (!localUser?.customer) {
      setInit(true);
    }

    setLoading(false);
  };

  useEffect(() => {
    checkTokenAndFetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay error={error} loading={loading}>
          <Modal
            centered
            maskClosable={false}
            footer={false}
            open={!init}
            closeIcon={false}
            width={'80%'}
            styles={{ mask: { backdropFilter: 'blur(10px)' }, }}
          >
            <InitPage user={user} ></InitPage>
          </Modal>
          <Card loading={loading}>
            <h1> Hallo  {user?.userName} </h1>
          </Card>
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};