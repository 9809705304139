import React from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card } from 'antd';
//Icons
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import SocialDistanceOutlinedIcon from '@mui/icons-material/SocialDistanceOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export const CourseTypeData = ({ record, card }) => {
    const items = [
        {
            key: '1',
            label: (<> <SocialDistanceOutlinedIcon fontSize="small" /> Altersspanne: </>),
            children: (<>{displayAgeRange(record?.ageRange?.minAge?.year, record?.ageRange?.minAge?.month) + " - " + displayAgeRange(record?.ageRange?.maxAge?.year, record?.ageRange?.maxAge?.month) || <Skeleton paragraph={{ rows: 0 }} />} </>),
            span: 24,
        },
        {
            key: '2',
            label: (<> <AccessTimeOutlinedIcon fontSize="small" /> Stundenzeit </>),
            children: (<>
                {record?.duration?.lessonTime + " Min." || <Skeleton paragraph={{ rows: 0 }} />}
            </>),
            span: 2,
        },
        {
            key: '3',
            label: (<> <AccessTimeOutlinedIcon fontSize="small" /> Gesamtzeit </>),
            children: (<>
                {record?.duration?.overallTime + " Min." || <Skeleton paragraph={{ rows: 0 }} />}
            </>),
            span: 2,
        },
        {
            key: '4',
            label: (<> <GroupOutlinedIcon fontSize="small" /> Kursgröße </>),
            children: (<>
                {record?.places + " Teilnehmer" || <Skeleton paragraph={{ rows: 0 }} />}
            </>),
            span: 24,
        },
    ]
    const response = (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                <Divider orientation='left' orientationMargin="0"><DisplaySettingsOutlinedIcon /> Daten </Divider>
                <Descriptions size="small" bordered layout="vertical" items={items} />
            </Col>
        </Row>
    )

    return (card ? <Card size="small">{response}</Card> : <>{response}</>);
};

function displayAgeRange(y, m) {
    const yString = (y === 1) ? "Jahr" : "Jahre";
    const mString = (m === 1) ? "Monat" : "Monate";
    return `${y} ${yString} ${m} ${mString}`;
}