import { Form, Tooltip, Input } from 'antd';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';

export const PriceInput = ({ tooltip = "Preis in Euro", label = "Preis", name = "price" }) => {
    return (
        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title={tooltip}>
            <Form.Item
                hasFeedback
                name={name}
                rules={[
                    {
                        required: true,
                        message: `Bitte einen ${label} angeben!`,
                    },
                    {
                        pattern: /^\d+(\.\d{1,2})?$/,
                        message: `Bitte einen gültigen ${label} eingeben!`,
                    },
                ]}
            >
                <Input
                    prefix={<EuroSymbolOutlinedIcon fontSize='small' />}
                    placeholder={`${label} in Euro`}
                    type="number"
                    step="1.00"
                />
            </Form.Item>
        </Tooltip>
    );
};