import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Card, Space, Carousel } from 'antd';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { getAccess, getImageType } from "../helpers";
import { EditButton, CreateButton, ModalDisplay, ErrorDisplay, ImageItem, ImageForm, EmptyDisplay } from "../../../../package";

export const ImageDisplay = ({ ghost, editable, accessibleId, mainApi, fileApi }) => {
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const getData = async () => {
        try {
            const response = await mainApi.get(`/image/all?accessibleIds=${accessibleId}&sortBy=createdAt`);
            setData(response.data.image);
        } catch (error) {
            setError("Probleme beim Laden des Moduls: " + error?.response?.data?.message && error?.message)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData();
        setRefresh(false)
    }, [open, refresh]);

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                    <Space>
                        <>
                            <ImageOutlinedIcon />
                            Bilder
                        </>
                        {!editable ? "" : <CreateButton text="Hinzufügen" onClick={() => setOpen(true)} />}
                    </Space>
                </Divider>}
                <ErrorDisplay error={error} loading={loading}>
                    {data.length === 0 ? <EmptyDisplay iconHeight={0} card text={"Keine Bilder Vorhanden"} onClick={() => editable ? setOpen(true) : setOpen(false)} />
                        : <Carousel autoplay>
                            {data.map(image => (
                                <ImageItem mainApi={mainApi} editable={editable} item={image} refresh={() => setRefresh(true)} />
                            ))}
                        </Carousel>}
                    <ModalDisplay
                        onCancel={() => { setOpen(false) }}
                        open={open}
                        content={
                            <ImageForm
                                mainApi={mainApi}
                                fileApi={fileApi}
                                accessibleId={accessibleId}
                                isSuccess={() => { setOpen(false) }}
                            />}
                    />
                </ErrorDisplay>
            </Col >
        </Row >
    );
};