import React, { useEffect, useState } from "react";
import { Row, Col, Divider, Card, Space } from 'antd';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { getAccess } from "../helpers";
import { EditButton, EmptyDisplay, CreateButton, ModalDisplay, ErrorDisplay, InformationForm } from "../../../../package";

export const InformationDisplay = ({ ghost, editable, accessibleId, mainApi }) => {
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [information, setDescription] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const getData = async () => {
        try {
            const response = await mainApi.get(`/information/one/${accessibleId}`);
            setDescription(response.data.information);
        } catch (error) {
            setError("Probleme beim Laden des Moduls: " + error?.response?.data?.message && error?.message)
        } finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData();
        setRefresh(false)
    }, [open, refresh]);

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                    <Space>
                        <><DescriptionOutlinedIcon />Information</>
                        {editable ?
                            !information ?
                                <CreateButton text="erstellen" onClick={() => setOpen(true)} /> :
                                <>
                                    <EditButton onClick={() => setOpen(true)} />
                                </>
                            : ("")
                        }
                    </Space>
                </Divider>}
                <ErrorDisplay error={error} loading={loading}>
                    {!information ?
                        <EmptyDisplay iconHeight={0} card text={"Keine Information Vorhanden"} onClick={() => editable ? setOpen(true) : setOpen(false)} />
                        :
                        <Card
                            headStyle={{ background: "lightgray" }}
                            extra={getAccess(information?.access)}
                            size="small"
                            title={<h6>{information?.title}</h6>}>
                            <div style={{ color: "gray" }}>{information?.subtitle}</div>
                            {<div dangerouslySetInnerHTML={{ __html: information?.text }} />}
                        </Card >}
                    <ModalDisplay
                        onCancel={() => setOpen(false)}
                        open={open}
                        content={
                            <InformationForm
                                mainApi={mainApi}
                                accessibleId={accessibleId}
                                uuid={information?.uuid}
                                isSuccess={() => { setOpen(false) }}
                            />
                        } />
                </ErrorDisplay>
            </Col>
        </Row>
    );
};