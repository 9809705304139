import React, { useEffect, useState } from "react";
import { Space, Row, Col, Divider, Card } from 'antd';
import { getFilter, ModalDisplay, ErrorDisplay, CreateButton, ListDisplay, SearchFilter, AwardItem, AwardForm } from "../..";
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

export const AwardList = ({ ghost, editable, courseTypeId = "", mainApi, fileApi, card, pagination, filterText, filterStatus }) => {
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getData = async (search = "", status = getFilter(filterStatus),) => {
        try {
            const response = await mainApi.get(`/award/all?courseTypeIds=${courseTypeId}&page=${currentPage}&limit=${pageSize}&search=${search}&status=${status}`);
            setData(response.data)
        } catch (error) {
            setError("Probleme beim Laden des Moduls: " + error?.response?.data?.message && error?.message)
        } finally {
            setLoading(false)
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true);
    };
    const handlePageSizeChange = (size) => {
        setCurrentPage(1);
        setPageSize(size);
        setRefresh(true);
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData();
        setRefresh(false)
    }, [open, refresh]);

    return (
        <Card
            title={!card ? "" : <> <EmojiEventsOutlinedIcon /> Abzeichen </>}
            headStyle={{ backgroundColor: '#9dcedf' }}
            extra={!card ? "" : !editable ? "" : <CreateButton size="middle" text="Hinzufügen" onClick={() => setOpen(true)} />}
        >
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                        <Space>
                            <><EmojiEventsOutlinedIcon />Abzeichen</>
                            {!editable ? "" : <CreateButton text="Hinzufügen" onClick={() => setOpen(true)} />}
                        </Space>
                    </Divider>}
                    <SearchFilter
                        text={filterText} status={filterStatus}
                        onSubmit={(values) => { getData(values.search, values.status) }}></SearchFilter>
                    <ErrorDisplay error={error} loading={loading}>
                        <ListDisplay
                            emptyText="Kein Abzeichen gefunden"
                            data={data.award}
                            listItem={(item) => <AwardItem editable mainApi={mainApi} item={item} refresh={() => setRefresh(true)} />}
                            maxHeight={550}
                            pagination={pagination ? "" : {
                                current: currentPage,
                                total: data?.totalPages,
                                onChange: handlePageChange,
                            }}
                            pageSize={{ current: pageSize, handle: handlePageSizeChange }}
                        />
                        {JSON.stringify(courseTypeId)}
                        <ModalDisplay
                            onCancel={() => { setOpen(false) }}
                            open={open}
                            content={
                                <AwardForm
                                    courseType={courseTypeId}
                                    mainApi={mainApi}
                                    fileApi={fileApi}
                                    isSuccess={() => {
                                        setOpen(false)
                                        setRefresh(true)
                                    }}
                                />}
                        />
                    </ErrorDisplay>
                </Col >
            </Row >
        </Card>
    );
};