import React, { useState } from "react";
import { Space, List, Col, Row } from 'antd';
import { EditButton, DeleteButton, ModalDisplay, UnitTimeForm } from "../..";

export const UnitTimeItem = ({ ghost, editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/unitTime/delete/${item.uuid}`)
        refresh(true)
    };
    const start = new Date(item?.start)

    return (
        <List.Item>
            <Row gutter={[8, 16]} justify="space-between">
                <Col flex>
                    {start.toLocaleDateString() + " von " + displayTime(item?.start) + " - " + displayTime(item?.end)}
                </Col>
                {!editable ? "" : <Col flex>
                    <Space>
                        <EditButton onClick={() => setOpen(true)} />
                        <DeleteButton handleDelete={() => handleDelete()} />
                    </Space>
                </Col>}
            </Row>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<UnitTimeForm
                    mainApi={mainApi}
                    uuid={item?.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item>
    )
};

function displayTime(string) {
    const date = new Date(string).toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" })
    return (date + " Uhr")
}