// Sidebar.js
import React, { useState, useEffect } from "react";
import { Button, Col, Divider, Flex, Row, Space } from "antd";
import { json, useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
//MyCourseIcons
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { CustomApi, SystemApi } from "../../urlConfig";
import { AccessibleDisplay, CourseTypeData, CourseTypeForm, CustomerData, CustomerForm, EditButton, ErrorDisplay, InformationTextData, ModalDisplay, MoreButton, ParticipantData, ParticipantForm } from "../../package";

export const OneParticipationPage = ({ layout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { uuid } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  const headerData = {
    title: `${data?.person?.name?.firstname + " " + data?.person?.name?.lastname}`,
    icon: <PoolOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager.god)
    setLoading(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await CustomApi.get(`/participant/one/${uuid}`);
      setData(response.data.participant);
    } catch (error) {
      setError(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTokenAndFetchData();
    const fetchData = async () => {
      await getData();
    };
    fetchData();
  }, [open, refresh]);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay error={error} loading={loading}>
          <Row gutter={[16, 16]} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Flex justify="space-between">
                <Button type="primary" size="small" icon={<ArrowBackOutlinedIcon fontSize="small" />} onClick={() => navigate("/participant")} >Zurück</Button>
                <Space>
                  <MoreButton text="Kunden" onClick={() => navigate(`/customer/${data.customerId}`)} />
                  {!edit ? "" : <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />}
                </Space>
              </Flex>
              <Divider style={{ margin: 10 }}></Divider>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <ParticipantData card data={data} />
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalDisplay
            onCancel={() => { setOpen(false) }}
            open={open}
            content={
              <ParticipantForm
                custApi={CustomApi}
                uuid={data?.uuid}
                isSuccess={() => {
                  setOpen(false)
                  setRefresh(true)
                }}
              />}
          />
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};