import React from "react";
import Navbar from "./Navbar";
import { Row, Col, Flex } from "antd";
import Header from "./Header";


const Layout = ({ children, layout, headerData, select }) => {
  return (
    <React.Fragment>
      <Navbar >
        <Flex vertical style={{ height: '100%', alignContent: 'flex-start'}}>
            <Header layout={layout} data={headerData} select={select} />
            <div style={{width:'100%', padding: 10, overflow: 'auto'}}>
            <main>{children}</main>
            </div>

        </Flex>
      </Navbar>
    </React.Fragment>
  );
};

export default Layout;
