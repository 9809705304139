import React, { useEffect, useState } from 'react';
import { Form, Button, Spin, Space, message, Row, Col, Tooltip, Select } from 'antd';
import { QuillEditor } from '../..';

import TocOutlinedIcon from '@mui/icons-material/TocOutlined';         
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';

export const InformationTextForm = ({ title, isSuccess, uuid, teachProfileId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        if (uuid) { getData() }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/informationText/one/${uuid}`);
            const data = {
                content: response?.data?.informationText?.content,
                goal: response?.data?.informationText?.goal,
                keyElements: response?.data?.informationText?.keyElements,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const setContentValueInForm = (value) => {
        form.setFieldsValue({
            content: value,
        });
    };
    const setKeyElementsValueInForm = (value) => {
        form.setFieldsValue({
            keyElements: value,
        });
    };
    const setGoalValueInForm = (value) => {
        form.setFieldsValue({
            goal: value,
        });
    };


    const onFinish = async (values) => {
        values.teachProfileId = teachProfileId;
        let response;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/informationText/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/informationText`, values);
            }
            isSuccess(response.data.informationText);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="ContactForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Information ändern' : 'Information anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[0, 0]}>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="content"
                                >
                                    <QuillEditor
                                        placeholder={<Space><TocOutlinedIcon />Inhalte</Space>}
                                        defaultValue={form.getFieldValue("content")}
                                        onChange={(e) => setContentValueInForm(e)}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="keyElements"
                                >
                                    <QuillEditor
                                        placeholder={<Space><KeyOutlinedIcon />Schlüsselelemente</Space>}
                                        defaultValue={form.getFieldValue("keyElements")}
                                        onChange={(e) => setKeyElementsValueInForm(e)}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="goal"
                                >
                                    <QuillEditor
                                        placeholder={<Space><SportsScoreOutlinedIcon />Ziel</Space>}
                                        defaultValue={form.getFieldValue("goal")}
                                        onChange={(e) => setGoalValueInForm(e)}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={12} md={16} lg={17} xl={18} ></Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};