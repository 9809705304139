import React from "react";
import { Row, Col } from "antd";
import { LogInForm } from "../../package";
import { AdminApi, CustomApi, SystemApi } from "../../urlConfig";

const Login = () => {
  return (
    <React.Fragment>
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col xs={24} sm={20} md={16} lg={12} xl={8}>
          <LogInForm mainApi={SystemApi} empApi={AdminApi} custApi={CustomApi}></LogInForm>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Login;
