import React, { useState } from "react";
import { Flex, Tag, Space, List, Collapse, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton, ModalDisplay, MoreButton, UnitData, ContactForm, ContactData, AvailableList, CommunicationList } from "../..";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const ContactItem = ({ ghost, editable, item, refresh, mainApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/contact/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item>
            <Row gutter={[8, 16]} justify="space-between">
                <Col flex>
                    {item?.person?.sex === "male" ? "Herr " : item?.person?.sex === "female" ? "Frau " : "Person "}
                    {item?.person?.name?.firstname + " " + item?.person?.name?.lastname}
                </Col>
                <Col flex>
                    <Space>
                        {/* <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.contactStatus?.msg)}</Tag> */}
                        <MoreButton text="mehr" onClick={() => navigate(`/contact/${item?.uuid}`)} />
                    </Space>
                </Col>
            </Row>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<ContactForm
                    mainApi={mainApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item>
    )
};

