import { Select, Form, Tooltip, Col } from 'antd';

export const AgeSelect = ({ tooltip, label, name, icon, maxRange = 10 }) => {
    return (
        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title={tooltip}>
            <Form.Item
                hasFeedback
                name={name}
                rules={[
                    {
                        required: true,
                        message: `Bitte eine gültige Anzahl von ${label} auswählen`,
                    },
                ]}
            >
                <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder={`${label}e`}
                >
                    {[...Array(maxRange + 1).keys()].map((value) => (
                        <Select.Option key={value} value={value} label={value === 1 ? `${value} ${label}` : `${value} ${label}e`}>
                            {icon}
                            {value === 1 ? (
                                value + " " + label
                            ) : (
                                value + " " + label + "e"
                            )}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
        </Tooltip>
    );
};