import React, { useEffect, useState } from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Space, Card } from 'antd';
import { CoachForm, CreateButton, EditButton, ErrorDisplay, ModalDisplay, MoreButton } from "../../../package";
//Icons
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
import { useNavigate } from "react-router-dom";

export const CoachData = ({ ghost, editable, employeeId, mainApi, name, card }) => {
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [location, setLocation] = useState({})
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        getData();
    }, [open, refresh]);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/coach/one/${employeeId}`);
            if (response.data.coach) {
                const temp = await mainApi.get(`/location/one/${response.data.coach.locationId}`);
                setLocation(temp.data.location)
            }
            setData(response.data.coach)
        } catch (error) {
            setError("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const items = [
        {
            key: '1',
            label: (<> <TocOutlinedIcon fontSize="small" /> Standort: </>),
            children: (<>{location?.locationAccessible?.informations[0]?.title || <Skeleton paragraph={{ rows: 0 }} />}</>),
            span: 24,
        },
    ]

    const response = (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {(!data || data?.length === 0) ?
                        !editable ? "" :
                            <Divider orientation='left' orientationMargin="0">
                                <Space>
                                    <SportsOutlinedIcon /> Trainer:
                                    <CreateButton text="Hinzufügen" onClick={() => setOpen(true)} />
                                </Space>
                            </Divider>
                        :
                        <>
                            {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                                <Space>
                                    <SportsOutlinedIcon /> Trainer:
                                    {!editable ? "" : <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />}
                                    <MoreButton text={"mehr"} onClick={() => navigate(`/coach/${data?.employeeId}`)} />
                                </Space>
                            </Divider>}
                            <ErrorDisplay error={error} loading={loading}>
                                <Descriptions size="small" bordered layout="vertical" items={items} />
                            </ErrorDisplay>

                        </>}
                </Col>
            </Row>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<CoachForm
                    name={name}
                    employeeId={employeeId}
                    mainApi={mainApi}
                    uuid={!data ? "" : employeeId}
                    isSuccess={() => {
                        setOpen(false)
                        setRefresh(true)
                    }}
                />} />
        </>
    );

    return (card ? <Card size="small">{response}</Card> : <>{response}</>)
};