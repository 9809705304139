// Sidebar.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Card, Avatar, Menu, Flex, Modal, Popconfirm, Button, FloatButton, Dropdown, Badge, Tag } from "antd";

import swimy from '../img/swimy.png'

//General Icons
import CloudSyncOutlinedIcon from '@mui/icons-material/CloudSyncOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';

//TitleIcons
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SportsOutlinedIcon from '@mui/icons-material/SportsOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
//MyCourseIcons
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

//CourseIcons
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
//Notification Icons
import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined';
import { Group } from "antd/es/avatar";




export function Sidebar(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState();


  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    console.log('click ', e);
    setCurrent(e.key);
  };


  const data = [
    {
      label: (<div onClick={() => navigate('/dashboard')}>Startseite <Tag color="green">A</Tag> </div>),
      key: 'home',
      icon: <HomeOutlinedIcon />,
    },
    {
      label: (<div onClick={() => navigate('/clients')}> Kunden <Tag color="orange">S</Tag></div>),
      label_sm: (<div onClick={() => navigate('/clients')}> Kunden </div>),
      key: 'clients',
      icon: <GroupOutlinedIcon />,
      children: [
        {
          label: (<div onClick={() => navigate('/customer')}> Kunden <Tag color="green">A</Tag></div>),
          label_sm: (<a onClick={() => navigate('/customer')}> Kunden </a>),
          key: 'coach',
          icon: <PersonOutlineOutlinedIcon />,
        },
        {
          label: (<div onClick={() => navigate('/participant')}> Teilnehmer<Tag color="green">A</Tag> </div>),
          label_sm: (<a onClick={() => navigate('/participant')}> Teilnehmer </a>),
          key: 'user',
          icon: <PoolOutlinedIcon />,
        },
        {
          label: (<div onClick={() => navigate('/participation')}> Buchungen<Tag color="green">A</Tag> </div>),
          label_sm: (<a onClick={() => navigate('/participation')}> Buchungen </a>),
          key: 'employee',
          icon: <StoreOutlinedIcon />,
        },
      ],
    },
    {
      label: (<div onClick={() => navigate('/course')}> Kurse <Tag color="orange">S</Tag> </div>),
      label_sm: (<div onClick={() => navigate('/course')}> Kurse </div>),
      key: 'courses',
      icon: <PoolOutlinedIcon />,
    },
    // {
    //   label: (<div onClick={() => navigate('/chat')}> Nachrichten <Tag color="red">V2</Tag> </div>),
    //   label_sm: (<div onClick={() => navigate('/chat')}> Nachrichten </div>),
    //   key: 'chat',
    //   icon: <ChatBubbleOutlineOutlinedIcon />,
    // },
    {
      label: (<div onClick={() => navigate('/courseType')}> Kursarten  <Tag color="green">A</Tag></div>),
      label_sm: (<div onClick={() => navigate('/courseType')}> Kursarten </div>),
      key: 'courseType',
      icon: <DisplaySettingsOutlinedIcon />,
    },
    {
      label: (<div onClick={() => navigate('/location')}> Standorte <Tag color="green">A</Tag> </div>),
      label_sm: (<div onClick={() => navigate('/location')}> Standorte </div>),
      key: 'locations',
      icon: <LocationOnOutlinedIcon />,
    },

    {
      label: (<div onClick={() => navigate('/employees')}> Mitarbeiter <Tag color="green">A</Tag></div>),
      label_sm: (<div onClick={() => navigate('/employees')}> Mitarbeiter </div>),
      key: 'employees',
      icon: <GroupOutlinedIcon />,
      children: [
        {
          label: (<div onClick={() => navigate('/coach')}> Trainer <Tag color="green">A</Tag></div>),
          label_sm: (<a onClick={() => navigate('/coach')}> Trainer </a>),
          key: 'coach',
          icon: <SportsOutlinedIcon />,
        },
        {
          label: (<div onClick={() => navigate('/user')}> User<Tag color="green">A</Tag> </div>),
          label_sm: (<a onClick={() => navigate('/user')}> User </a>),
          key: 'user',
          icon: <PersonOutlineOutlinedIcon />,
        },
        {
          label: (<div onClick={() => navigate('/employee')}> Mitarbeiter<Tag color="green">A</Tag> </div>),
          label_sm: (<a onClick={() => navigate('/employee')}> Mitarbeiter </a>),
          key: 'employee',
          icon: <GroupOutlinedIcon />,
        },
      ],
    },
    // {
    //   label: (<div onClick={() => navigate('/applicants')}> Bewerber <Tag color="red">V2</Tag></div>),
    //   label_sm: (<div onClick={() => navigate('/applicants')}> Bewerber </div>),
    //   key: 'applicants',
    //   icon: <PersonAddAltOutlinedIcon />,
    // },
    // {
    //   label: (<div> Verwaltung <Tag color="red">V2</Tag></div>),
    //   label_sm: (<div> Verwaltung </div>),
    //   key: 'accounting',
    //   icon: <DashboardCustomizeOutlinedIcon />,
    //   children: [
    //     {
    //       label: (<div onClick={() => navigate('/accounting')}> Finanzen <Tag color="red">V2</Tag> </div>),
    //       label_sm: (<a onClick={() => navigate('/accounting')}> Finanzen </a>),
    //       key: 'accounting',
    //       icon: <AccountBalanceOutlinedIcon />,
    //     },
    //     {
    //       label: (<div onClick={() => navigate('/wages')}> Gehälter <Tag color="red">V2</Tag> </div>),
    //       label_sm: (<a onClick={() => navigate('/wages')}> Gehälter </a>),
    //       key: 'wages',
    //       icon: <AttachMoneyOutlinedIcon />,
    //     },
    //   ],
    // },
    // {
    //   label: (<div onClick={() => navigate('/setting')}> Einstellungen <Tag color="orange">S</Tag></div>),
    //   key: 'setting',
    //   icon: <SettingsOutlinedIcon />,
    // },
    // {
    //   label: (<div onClick={() => navigate('/profile')}> Profil <Tag color="red">V2</Tag></div>),
    //   label_sm: (<div onClick={() => navigate('/profile')}> Profil </div>),
    //   key: 'profile',
    //   icon: <AccountBoxOutlinedIcon />,
    // },
  ];


  switch (props.layout) {
    case "md":
      return ("");

    case "mobile":
      return (
        <React.Fragment>
          <Row gutter={[16, 0]} style={{ backgroundColor: '#1a4568', display: 'flex', overflowX: "auto", flexWrap: "nowrap", padding: 12 }}>
            {data.map((part, index) => (
              part.label_sm ? (
                <Col xs={6} sm={6} align="center" key={index}>
                  {part.children ? (
                    <Card size="small" style={{ backgroundColor: "transparent", border: "transparent", color: "white" }} onClick={() => setOpen(true)}>
                      <Dropdown overlay={
                        <Menu>
                          {part.children.map((child, childIndex) => (
                            <Menu.Item style={{ padding: 15 }} key={childIndex} onClick={() => setOpen(false)}>
                              {child.icon} {child.label_sm}
                            </Menu.Item>
                          ))}
                        </Menu>
                      } trigger={['click']}>
                        <div onClick={(e) => e.preventDefault()}>
                          {part.icon} {part.label_sm}
                        </div>
                      </Dropdown>
                    </Card>
                  ) : (
                    <Card size="small" style={{ background: "transparent", border: "transparent", color: "white" }}>
                      {part.icon}
                      {part.label_sm}
                    </Card>
                  )}
                </Col>
              ) : ("")
            ))}
          </Row>
        </React.Fragment>
      );

    default:
      return (
        <React.Fragment>
          <Flex vertical align='start' gap='small' style={{ backgroundColor: '#1a4568', height: '100vh', width: '100%' }}>
            <Card bordered={0} style={{ background: 'transparent', width: '100%', color: '#fff' }}>
              <Flex justify="space-between">
                <h4 className="mt-2" >CourseSnyc </h4>
                <Avatar src={<img src={swimy} alt="avatar" />} />
              </Flex>
            </Card>

            <Menu onClick={onClick} selectedKeys={[current]} mode="inline" items={data} style={{ background: "transparent", color: '#fff', width: "100%" }} />

            <Col>
            </Col>
          </Flex>
        </React.Fragment>

      );

  }
};


export default Sidebar;