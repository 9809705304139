import React from "react";
import { Descriptions, Row, Col, Divider, Card, Tag } from 'antd';
//Icons
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import ContrastOutlinedIcon from '@mui/icons-material/ContrastOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

export const UserData = ({ data, card }) => {

    const items = [
        {
            key: '1',
            label: (<> <AlternateEmailOutlinedIcon fontSize="small" /> Email Bestätigung 1: </>),
            children: (<>{getBool(data?.setting?.emailConfirmationOne)}</>),
        },
        {
            key: '2',
            label: (<> <AlternateEmailOutlinedIcon fontSize="small" /> Email Bestätigung 2: </>),
            children: (<>{getBool(data?.setting?.emailConfirmationTwo)}</>),
        },
        {
            key: '3',
            label: (<> <AlternateEmailOutlinedIcon fontSize="small" /> Email Bestätigung 3: </>),
            children: (<>{getBool(data?.setting?.emailConfirmationThree)}</>),
        },
        {
            key: '4',
            label: (<> <ContrastOutlinedIcon fontSize="small" /> Theme: </>),
            children: (<>{data?.setting?.theme}</>),
        },
        {
            key: '5',
            label: (<> <LockPersonOutlinedIcon fontSize="small" /> 2 Faktor: </>),
            children: (<>{getBool(data?.setting?.twoFactorAuth)}</>),
        },
    ]

    if (card) {
        return (
            <Card
                size="small"
                title={<Divider orientation='left' orientationMargin="0"><SettingsOutlinedIcon /> Einstellungen </Divider>}
                headStyle={{ backgroundColor: '#9dcedf' }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Descriptions size="small" bordered layout="vertical" items={items} />
                    </Col>
                </Row>
            </Card>

        );
    }
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    <Divider orientation='left' orientationMargin="0"><SettingsOutlinedIcon /> Einstellungen </Divider>
                    <Descriptions size="small" bordered layout="horizontal" items={items} />
                </Col>
            </Row>
        </>

    );
};

function getBool(value) {
    if (value) {
        return (<Tag color="green">Aktiv</Tag>)
    }
    return (<Tag color="orange">Aus</Tag>)
}