import { Select, Form, Tooltip } from 'antd';

export const SelectWithArray = ({ tooltip, label, name, icon, start = 0, end = 10, steps = 1 }) => {
    const options = [];

    for (let value = start; value <= end; value += steps) {
        options.push(
            <Select.Option key={value} value={value}>
                {icon}
                {`${value} ${label}`}
            </Select.Option>
        );
    }

    return (
        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title={tooltip}>
            <Form.Item
                hasFeedback
                name={name}
                rules={[
                    {
                        required: true,
                        message: `Bitte ein ${label.toLowerCase()} auswählen`,
                    },
                ]}
            >
                <Select style={{ width: '100%' }} placeholder={`${label}`}  showSearch>
                    {options}
                </Select>
            </Form.Item>
        </Tooltip>
    );
};