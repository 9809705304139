import { Select, Form, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';

export const UnitSelect = ({ onChange, disabled, mainApi, locationId = "", filterStatus = "", tooltip = "Raum Wählen", label = "Raum", name = "unit" }) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)


    const getData = async () => {
        try {
            const response = await mainApi.get(`/unit/all?status=${filterStatus}&locationIds=${locationId}`);

            setData(response.data.unit)
        } catch (error) {
            setError(true)
            message.error("Probleme beim Laden des Moduls: " + error?.response?.data?.message)
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title={tooltip}>
            <Form.Item
                hasFeedback
                name={name}
                rules={[{ required: true, message: `Bitte die ${label} wählen` }]}
            >
                <Select
                    disabled={error || disabled}
                    showSearch
                    onChange={onChange}
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                    style={{ width: '100%' }}
                    placeholder={label}
                    options={
                        data?.map((value) => ({
                            value: value?.uuid,
                            label: value?.unitAccessible?.informations[0]?.title
                        }))
                    }
                >
                </Select>
            </Form.Item>
        </Tooltip >
    );
};