import React, { useState } from "react";
import { Card, Space } from 'antd';
import { InformationDisplay, ModalDisplay, ImageDisplay, DocumentDisplay, StatDisplay, EditButton, DescriptionDisplay } from "../../../package"

export const AccessibleDisplay = ({ mainApi, noCard, ghost, information, stat, description, document, image, accessibleId, editable, title, modalContent, fileApi }) => {
    const [open, setOpen] = useState(false);

    if (noCard) {
        return (<Space direction="vertical" style={{ width: "100%" }}>
            {!information ? "" : <InformationDisplay ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi}></InformationDisplay>}
            {!description ? "" : <DescriptionDisplay ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi}></DescriptionDisplay>}
            {!stat ? "" : <StatDisplay filterText filterStartDate ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi}></StatDisplay>}
            {!image ? "" : <ImageDisplay ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi} fileApi={fileApi}></ImageDisplay>}
            {!document ? "" : <DocumentDisplay filterText filterStartDate ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi} fileApi={fileApi}></DocumentDisplay>}
        </Space>)
    }

    return (
        <Card
            size="small"
            headStyle={{ backgroundColor: '#9dcedf' }}
            extra={!modalContent ? ("") : <Space gap='small'>
                <EditButton size="middle" onClick={() => setOpen(true)} />
            </Space>}
            title={title}>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={modalContent}
            />
            <Space direction="vertical" style={{ width: "100%" }}>
                {!information ? "" : <InformationDisplay ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi}></InformationDisplay>}
                {!description ? "" : <DescriptionDisplay ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi} ></DescriptionDisplay>}
                {!stat ? "" : <StatDisplay filterText filterStartDate ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi}></StatDisplay>}
                {!image ? "" : <ImageDisplay ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi} fileApi={fileApi}></ImageDisplay>}
                {!document ? "" : <DocumentDisplay filterText filterStartDate ghost={ghost} editable={editable} accessibleId={accessibleId} mainApi={mainApi} fileApi={fileApi}></DocumentDisplay>}
            </Space>
        </Card >
    );
};