import React, { useState } from "react";
import { StatForm, DescriptionForm, StepDisplay, ImageForm, LocationForm, EmployeePersonForm, EmployeeContactForm, EmployeeBankForm, EmployeeTaxForm } from "../..";
//Icons
import EditLocationAltOutlinedIcon from '@mui/icons-material/EditLocationAltOutlined';
import InsertEmoticonOutlinedIcon from '@mui/icons-material/InsertEmoticonOutlined';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import ExposureOutlinedIcon from '@mui/icons-material/ExposureOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';

export const FullEmployeeForm = ({ isSuccess, empApi, fileApi, uuid }) => {
    const [current, setCurrent] = useState(0);
    const [employee, setEmployee] = useState(uuid);
    const next = () => {
        if (current + 1 === items.length) {
            isSuccess()
        } else {
            setCurrent(current + 1);
        }
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const state = () => { return (current + 1 === items.length) };
   
    const items = [
        {
            key: 2,
            title: 'Person',
            icon: <PersonOutlineOutlinedIcon />,
            content: (<EmployeePersonForm
                empApi={empApi}
                uuid={uuid}
                title={state ? "Weiter" : "Fertig"}
                isSuccess={() => { next() }}
            />),
        },
        {
            key: 3,
            title: "Kontakt",
            content: (<EmployeeContactForm
                empApi={empApi}
                uuid={uuid}
                title={state ? "Weiter" : "Fertig"}
                isSuccess={() => { next() }}
            />),
            icon: <AlternateEmailOutlinedIcon />,
        },
        {
            key: 4,
            title: "Bank",
            content: (<EmployeeBankForm
                empApi={empApi}
                uuid={uuid}
                title={state ? "Weiter" : "Fertig"}
                isSuccess={() => { next() }}
            />),
            icon: <AccountBalanceOutlinedIcon />,
        },
        {
            key: 5,
            title: "Weitere",
            content: (<EmployeeTaxForm
                empApi={empApi}
                uuid={uuid}
                title={state ? "Weiter" : "Fertig"}
                isSuccess={() => { next() }}
            />),
            icon: <DisplaySettingsOutlinedIcon />,
        },
    ];
    return (<StepDisplay items={items} prev={() => prev()} next={() => next()} current={current} />);
};