import React, { useState } from "react";
import { Flex, Tag, Space, List, Collapse, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton, ModalDisplay, MoreButton, FullEmployeeForm, EmployeePersonData, CoachData, ManagerData } from "../..";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const EmployeeItem = ({ editable, item, refresh, empApi, mainApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await empApi.patch(`/employee/delete/${item.uuid}`)
        await mainApi.patch(`/coach/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item style={{ padding: 0 }}>
            <Collapse
                size="small"
                type
                ghost
                items={[{
                    key: item.uuid,
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                                <Flex justify="space-between">
                                    <Space>{item?.person?.name?.firstname + " " + item?.person?.name?.lastname}</Space>
                                </Flex>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <Flex justify="end">
                                    <Space>
                                        <Tag color="gray">{new Date(item?.start || item?.createdAt).toLocaleDateString()}</Tag>
                                        <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.employeeStatus?.msg)}</Tag>
                                        <MoreButton text="mehr" onClick={() => navigate(`/employee/${item?.uuid}`)} />
                                    </Space>
                                </Flex>
                            </Col>
                        </Row>
                    ),
                    children: (<>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <EmployeePersonData data={item} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <CoachData name={item?.person?.name?.firstname + " " + item?.person?.name?.lastname} editable mainApi={mainApi} employeeId={item?.uuid} />
                                <ManagerData editable empApi={empApi} employeeId={item?.uuid} />
                            </Col>
                            {!editable ? "" : <Col flex>
                                <Space>
                                    <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                    <DeleteButton handleDelete={() => handleDelete()} />
                                </Space>
                            </Col>}
                        </Row>
                    </>)
                }]}>
            </Collapse>
            <ModalDisplay
                onCancel={() => {
                    setOpen(false)
                    refresh(true)
                }}
                open={open}
                content={<FullEmployeeForm
                    empApi={empApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item>
    )
};