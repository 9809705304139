import React, { useEffect, useState } from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card, Space } from 'antd';
import { EditButton, EmptyDisplay, ErrorDisplay, InformationTextForm, ModalDisplay } from "../../../package";
//Icons
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import KeyOutlinedIcon from '@mui/icons-material/KeyOutlined';
import SportsScoreOutlinedIcon from '@mui/icons-material/SportsScoreOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';

export const InformationTextData = ({ editable, ghost, card, teachProfileId, mainApi }) => {
    const [data, setData] = useState({})
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        getData();
    }, [open, refresh]);

    const getData = async () => {
        if (!teachProfileId) { setData([]) }
        else {
            try {
                setLoading(true);
                const response = await mainApi.get(`/informationText/one/${teachProfileId}`);
                setData(response.data.informationText)
            } catch (error) {
                setError("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const items = [
        {
            key: '1',
            label: (<> <TocOutlinedIcon fontSize="small" /> Inhalte: </>),
            children: (<>{<div dangerouslySetInnerHTML={{ __html: data?.content }} /> || <Skeleton paragraph={{ rows: 0 }} />}</>),
            span: 24,
        },
        {
            key: '2',
            label: (<> <KeyOutlinedIcon fontSize="small" /> Schlüsselelemente: </>),
            children: (<>{<div dangerouslySetInnerHTML={{ __html: data?.keyElements }} /> || <Skeleton paragraph={{ rows: 0 }} />}</>),
            span: 24,
        },
        {
            key: '3',
            label: (<> <SportsScoreOutlinedIcon fontSize="small" /> Ziel: </>),
            children: (<>{<div dangerouslySetInnerHTML={{ __html: data?.goal }} /> || <Skeleton paragraph={{ rows: 0 }} />}</>),
            span: 24,
        },
    ]

    const response = (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                        <Space>
                            <ContentPasteOutlinedIcon /> Inhalte:
                            {(!editable || !data) ? "" : <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />}
                        </Space>
                    </Divider>}
                    <ErrorDisplay error={error} loading={loading}>
                        {(!data || data?.length === 0) ?
                            <EmptyDisplay iconHeight={0} card text={"Keine Informationen Vorhanden"} onClick={() => setOpen(editable ? true : false)} /> :
                            <Descriptions size="small" bordered layout="vertical" items={items} />
                        }
                    </ErrorDisplay>
                </Col>
            </Row>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<InformationTextForm
                    teachProfileId={teachProfileId}
                    mainApi={mainApi}
                    uuid={!data ? "" : teachProfileId}
                    isSuccess={() => {
                        setOpen(false)
                        setRefresh(true)
                    }}
                />} />
        </>
    )

    return (card ? <Card size="small">{response}</Card> : <>{response}</>);
};