import React from "react";
import { Descriptions, Row, Col, Divider, Card, Tag, Skeleton, Space } from 'antd';
//Icons
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export const EmployeePersonData = ({ data, card }) => {
    const { person, phone, email } = data;
    const { sex, birthdate, birthName, name } = person;
    const { title, prefix, firstname, addition, lastname } = name;

    const items = [
        {
            key: '1',
            label: (<> <PersonOutlineOutlinedIcon fontSize="small" /> Name: </>),
            children: (<>
                <div>
                    {sex === "male" ? "Herr" : sex === "female" ? "Frau" : "Person"}
                    {` ${title ? title : ""} ${prefix ? prefix : ""} ${firstname} ${addition ? addition : ""} ${lastname}`}
                </div>
                <div>
                    {birthName.prefix !== prefix || birthName.addition !== addition || birthName.lastname !== lastname ?
                        ` ehem.: ${birthName.prefix ? birthName.prefix : ""} ${firstname} ${birthName.addition ? birthName.addition : ""} ${birthName.lastname}` : ""}
                </div></>),
            span: 24,
        },
        {
            key: '2',
            label: (<> <CakeOutlinedIcon fontSize="small" /> Geburtsdatum: </>),
            children: (<>
                {new Date(birthdate).toLocaleDateString()}
                {` (${calculateAge(birthdate).years} Jahre)`}
            </>),
            span: 24,
        },
        {
            key: '3',
            label: (<a href={`tel:${phone}`}> <LocalPhoneOutlinedIcon fontSize="small" /> Telefon: </a>),
            children: (<>{phone.phoneNumber || <Skeleton paragraph={{ rows: 0 }} />}</>),
            span: 24,
        },
        {
            key: '4',
            label: (<a href={`mailto:${email}`}> <EmailOutlinedIcon fontSize="small" /> Email:</a>),
            children: (<>{email || <Skeleton paragraph={{ rows: 0 }} />}</ >),

            span: 24,
        },
    ]

    if (card) {
        return (
            <Card
                size="small"
                title={<Divider orientation='left' orientationMargin="0"><LockPersonOutlinedIcon /> Daten </Divider>}
                headStyle={{ backgroundColor: '#9dcedf' }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Descriptions size="small" bordered layout="vertical" items={items} />
                    </Col>
                </Row>
            </Card>

        );
    }
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    <Divider orientation='left' orientationMargin="0"><LockPersonOutlinedIcon /> Daten </Divider>
                    <Descriptions size="small" bordered layout="vertical" items={items} />
                </Col>
            </Row>
        </>

    );
};

function calculateAge(birthdate) {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();

    let ageYears = currentDate.getFullYear() - birthDate.getFullYear();
    let ageMonths = currentDate.getMonth() - birthDate.getMonth();
    let ageDays = currentDate.getDate() - birthDate.getDate();

    if (ageDays < 0) {
        ageMonths--;
        const lastMonthDays = getDaysInMonth(currentDate.getMonth() - 1, currentDate.getFullYear());
        ageDays = lastMonthDays + ageDays;
    }

    if (ageMonths < 0) {
        ageYears--;
        ageMonths = 12 + ageMonths;
    }

    return {
        years: ageYears,
        months: ageMonths,
        days: ageDays
    };
}

function getDaysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
}

function toAgeDisplay(age) {
    let y = age.years + " Jahre";
    let m = age.months + " Monate";
    if (age.years === 1) { y = age.years + " Jahr" }
    if (age.months === 1) { m = age.months + " Monat" }
    return y + " und " + m;
}