import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip } from 'antd';
import { QuillEditor, SelectWithArray, SelectWithData, PriceInput } from '../..';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';

export const UnitForm = ({ title, isSuccess, uuid, locationId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/unit/one/${uuid}`);
            const unit = {
                places: response?.data?.unit?.places,
                price: response?.data?.unit?.price,
                status: response?.data?.unit?.unitStatus?.statusValue,
                title: response?.data?.unit?.unitAccessible?.informations[0]?.title,
                subtitle: response?.data?.unit?.unitAccessible?.informations[0]?.subtitle,
                text: response?.data?.unit?.unitAccessible?.informations[0]?.text,
            }
            form.setFieldsValue(unit);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const setEditorValueInForm = (value) => {
        form.setFieldsValue({
            text: value,
        });
    };

    const onFinish = async (values) => {
        let response;
        values.userId = user?.uuid;
        values.locationId = locationId;
        values.price = parseFloat(values.price);
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/unit/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/unit`, values);
            }
            isSuccess(response.data.unit);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="UnitForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical" a
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Raum ändern' : 'Raum anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><VisibilityOutlinedIcon /> Darstellung</Divider>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="title"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Titel angeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Title ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Untertitel des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="subtitle"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Untertitel angeben!',
                                    },
                                    {
                                        max: 100,
                                        message: 'Der Untertitel ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Untertitel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="text"
                                >
                                    <QuillEditor placeholder={"Beschreibung"} defaultValue={form.getFieldValue("text")} onChange={setEditorValueInForm} />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        {/*Weitere Infos*/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><AddCircleOutlineOutlinedIcon /> Daten </Divider>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <SelectWithArray
                                start={0}
                                end={30}
                                label="Plätze"
                                tooltip={"Anzahl an gleichzeitig möglichen Kursen"}
                                name="places"
                                icon={<GroupsOutlinedIcon fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <PriceInput
                                label="Preis"
                                tooltip={"Kosten des Raumes pro Std in Euro"}
                                name="price"
                            />
                        </Col>

                        <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Wie soll der Raum im System angezeigt werden?"}
                                name="status"
                                data={[
                                    { value: "ACTIVE", label: "Sichtbar für alle" },
                                    { value: "INTERNAL", label: "Nur Intern für Verwaltung sichtbar" },
                                    { value: "SOON", label: "Ankündigen: für Trainer sichtbar" },
                                ]}
                            />

                        </Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};