import React from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card, Space } from 'antd';

//Icons
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

export const AdressDisplay = ({ data, card, ghost }) => {

    const items = [
        {
            key: '1',
            label: (<> <HomeOutlinedIcon fontSize="small" /> Adresse: </>),
            children: (<Space direction="vertical">
                {data?.street || <Skeleton paragraph={{ rows: 0 }} />}
                {data?.postalCode + " " + data?.city || <Skeleton paragraph={{ rows: 0 }} />}
                {data?.country || <Skeleton paragraph={{ rows: 0 }} />}
            </Space>),
            span: 24,
        },
    ]

    const response = (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                {ghost ? "" : <Divider orientation='left' orientationMargin="0"><HomeOutlinedIcon /> Adresse </Divider>}
                <Descriptions size="small" bordered layout="vertical" items={items} />
            </Col>
        </Row>
    );

    return (
        card ?
            <Card size="small">
                {response}
            </Card >
            :
            { response }
    );
};