import React, { useState } from "react";
import { Flex, Tag, Space, List, Collapse, Col, Row } from 'antd';
import { EditButton, DeleteButton, ModalDisplay, CommunicationForm } from "../..";

export const CommunicationItem = ({ ghost, editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/communication/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item style={{ padding: 0 }}>
            <Collapse
                size="small"
                type
                ghost
                items={[{
                    key: item.uuid,
                    label: (
                        <Row gutter={[8, 16]} justify="space-between">
                            <Col flex>{item?.reason}</Col>
                            <Col flex>
                                <Space>
                                    <Tag color="gray">{new Date(item?.updatedAt).toLocaleDateString()}</Tag>
                                    {getStatus(item?.communicationStatus)}
                                </Space>
                            </Col>
                        </Row>
                    ),
                    children: (<>
                        {!editable ? "" :
                            <Space>
                                <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                <DeleteButton text={"Löschen"} handleDelete={() => handleDelete()} />
                            </Space>
                        }
                        <div dangerouslySetInnerHTML={{ __html: item?.content }} />
                    </>)
                }]}>
            </Collapse>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<CommunicationForm
                    mainApi={mainApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item>
    )
};


function getStatus(status) {
    switch (status.statusValue) {
        case 'OPEN':
            return (<Tag color="green">{status.msg}</Tag>)
        case 'TODO':
            return (<Tag color="orange">{status.msg}</Tag>)
        case 'FINISHED':
            return (<Tag color="lightgray">{status.msg}</Tag>)
    }
}



