// Sidebar.js
import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import Layout from "../../layout/Layout";
//MyCourseIcons
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import { ErrorDisplay, EmployeeList } from "../../package";
import { AdminApi, SystemApi } from "../../urlConfig";

export const EmployeePage = ({ layout }) => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);

  const [user, setUser] = useState({});
  const headerData = {
    title: 'Mitarbeiter',
    icon: <GroupsOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager?.god)
    setLoading(false);
  };

  useEffect(() => {
    checkTokenAndFetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay loading={loading}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
              <EmployeeList editable={edit} empApi={AdminApi} mainApi={SystemApi} />
            </Col>
          </Row>
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};