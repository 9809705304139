import React, { useState } from "react";
import { Flex, Tag, Space, List, Collapse, Row, Col } from 'antd';
import { useNavigate } from "react-router-dom";
import { CourseTypeForm, EditButton, DeleteButton, ModalDisplay, MoreButton, CourseTypeData, InformationTextData } from "../../../package";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const CourseTypeItem = ({ editable, item, refresh, mainApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/courseType/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item style={{ padding: 0 }}>
            <Collapse
                size="small"
                type
                ghost
                items={[{
                    key: item.uuid,
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
                                <Flex justify="space-between">
                                    <Space>{item?.courseTypeAccessible?.informations[0]?.title + " - " + item?.courseTypeAccessible?.informations[0]?.subtitle}</Space>
                                </Flex>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
                                <Flex justify="end">
                                    <Space>
                                        <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.courseTypeStatus?.msg)}</Tag>
                                        <MoreButton text="mehr" onClick={() => navigate(`/courseType/${item?.uuid}`)} />
                                    </Space>
                                </Flex>
                            </Col>
                        </Row>
                    ),
                    children: (<>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <div dangerouslySetInnerHTML={{ __html: item?.courseTypeAccessible?.informations[0]?.text }} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <CourseTypeData record={item} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <InformationTextData editable teachProfileId={item?.teachProfileId} mainApi={mainApi} />
                            </Col>
                            {!editable ? "" : <Col flex>
                                <Space>
                                    <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />
                                    <DeleteButton handleDelete={() => handleDelete()} />
                                </Space>
                            </Col>}
                        </Row>

                    </>)
                }]}>
            </Collapse >
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<CourseTypeForm
                    mainApi={mainApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item >
    )
};

