// Sidebar.js
import React, { useState, useEffect } from "react";
import { Col, Row } from "antd";
import Layout from "../../layout/Layout";

import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import { BillList, ErrorDisplay, ParticipantList } from "../../package";
import { CustomApi } from "../../urlConfig";

export const BillPage = ({ layout }) => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);

  const headerData = {
    title: 'Rechnungen',
    icon: <DisplaySettingsOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager?.god)
    setLoading(false);
  };

  useEffect(() => {
    checkTokenAndFetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay loading={loading}>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
              <BillList editable={edit} custApi={CustomApi} />
            </Col>
          </Row>
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};