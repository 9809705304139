import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select, DatePicker, Checkbox, Card } from 'antd';

import { ErrorDisplay } from '../../components/displays/errorDisplay';
import { DeleteButton } from '../../components/buttons/deleteButton';


const { Option } = Select;
export const StornoForm = ({ isSuccess, createdAt = "", courseDates = [] }) => {
    const [loading, setLoading] = useState(false)
    const { free, fee, full, msg } = handleCancellationStatus(createdAt, courseDates)

    return (
        <>
            <Row gutter={[8, 8]}>
                <Col span={24}>
                    <h5>Stornierungsmöglichkeiten</h5>
                </Col>
                <ErrorDisplay error={free === 0 ? msg : ""}>
                    <Col span={24}>
                        <Card extra={<DeleteButton title='Stornieren?' text="Stornieren" handleDelete={() => { isSuccess("FREE_CANCELED") }}></DeleteButton>} type='inner' size='small' style={{ backgroundColor: "rgba(0, 255, 0, 0.3)" }}>
                            <Card.Meta style={{ opacity: free }}
                                title="1. Kostenfreie Stornierung"
                                description="Bis zu 7 Tagen nach Kursanmeldung für den Fall, dass diese nicht in den Zeitraum von 5 Tage vor Kursbeginn fallen."
                            >
                            </Card.Meta>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card extra={<DeleteButton title='Stornieren?' text="Stornieren" handleDelete={() => { isSuccess("FEE_CANCELED") }}></DeleteButton>} type='inner' size='small' style={{ backgroundColor: "rgba(255, 255, 0, 0.3)" }}>
                            <Card.Meta style={{ opacity: fee }}
                                title="2. Stornierungsgebühr 15€"
                                description="Bei einer Stornierung später als 7 Tage nach Anmeldung und früher als 5 Tage vor Kursbeginn, erheben wir eine Bearbeitungsgebühr von 15€ für eine Stornierung."
                            >
                            </Card.Meta>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Card extra={<DeleteButton title='Stornieren?' text="Stornieren" handleDelete={() => { isSuccess("FULL_CANCELED") }}></DeleteButton>} type='inner' size='small' style={{ backgroundColor: "rgba(255, 0, 0, 0.3)" }}>
                            <Card.Meta style={{ opacity: full }}
                                title="3. Stornierung gegen die volle Kursgebühr"
                                description="Ab 5 Tage vor Kursbeginn ist eine Stornierung nur gegen die Entrichtung der vollen Kursgebühr möglich"
                            >
                            </Card.Meta>
                        </Card>
                    </Col>
                    <Col span={24}>
                        <Button onClick={() => { isSuccess() }} block type='primary'>{msg}</Button>
                    </Col>
                </ErrorDisplay>
            </Row>

        </>
    );
};

function handleCancellationStatus(createdAt, courseDates) {
    const now = new Date();
    const courseStartDate = findStartDate(courseDates);
    if (courseStartDate == null) {
        return { free: 0, fee: 0, full: 0, msg: "Nicht Möglich" }
    }
    const daysUntilCourseStart = daysBetweenDates(now, courseStartDate);
    const daysSinceBooking = daysBetweenDates(createdAt, now);

    if (daysUntilCourseStart < 5) {
        // 5 Tage oder weniger vor Kursbeginn -> Keine Stornierung möglich
        return { free: 1, fee: 1, full: 1, msg: "Stornierung nach Punkt 3" }
    } else if (daysSinceBooking <= 7 && daysUntilCourseStart >= 5) {
        // Buchung weniger oder genau 7 Tage her und Kursbeginn noch mindestens 5 Tage hin -> Stornierung kostenlos möglich
        return { free: 1, fee: 1, full: 1, msg: "Stornierung nach Punkt 1" }
    } else if (daysSinceBooking > 7 && daysUntilCourseStart >= 5) {
        // Buchung mehr als 7 Tage her und Kursbeginn noch mindestens 5 Tage hin -> Stornierung gegen Gebühr möglich
        return { free: 1, fee: 1, full: 1, msg: "Stornierung nach Punkt 2" }
    }
}

function daysBetweenDates(date1, date2) {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const startDate = new Date(date1);
    const endDate = new Date(date2);

    const differenceInMilliseconds = endDate - startDate; //now - 7 days before
    const differenceInDays = Math.floor(differenceInMilliseconds / oneDayInMilliseconds);

    return differenceInDays;
}

function findStartDate(courseDates) {
    if (courseDates.length === 0) { return null }
    const sortedDates = courseDates.sort((a, b) => new Date(a.time.publicStart) - new Date(b.time.publicStart));
    return new Date(sortedDates[0].time.publicStart);
}
