// Sidebar.js
import React, { useState, useEffect } from "react";
import { Divider, Col, Flex, Row } from "antd";
import Layout from "../../layout/Layout";
//MyCourseIcons
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import { ErrorDisplay, CourseList, LocationSelect } from "../../package";
import { CustomApi, SystemApi } from "../../urlConfig";

export const CoursePage = ({ layout }) => {
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [location, setLocation] = useState("");

  const headerData = {
    title: 'Kurse',
    icon: <DisplaySettingsOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager?.god)
    setLoading(false);
  };

  const handleLocationSelect = (e) => {
    setLocation(e)
    setLoading(true)
  };


  useEffect(() => {
    checkTokenAndFetchData();
  }, [loading]);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay loading={loading}>
          <Row gutter={[16, 4]}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Flex justify="space-between">
                <LocationSelect ghost mainApi={SystemApi} onChange={(e) => handleLocationSelect(e)} />
              </Flex>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <Divider style={{ margin: 10 }}></Divider>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
              <CourseList editable={edit} custApi={CustomApi} mainApi={SystemApi} locationIds={location}></CourseList>
            </Col>
          </Row>
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};