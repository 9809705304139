import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdminApi, SystemApi } from '../urlConfig';
import axios from 'axios';

import { Row, Col, Button, Container, Form, Card, Menu, Tabs, Input, message, Flex, Avatar, Space } from "antd";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import swimy from '../img/swimy.png';

export function Header({ mode, data, select, isOpen, layout }) {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await SystemApi.post('/logout');
      await AdminApi.post('/logout');
      localStorage.removeItem("user");
      localStorage.removeItem("manager");
      localStorage.setItem("customToken", false);

      window.location.reload();

    } catch (error) {
      message.error(`Abmeldung Fehlgeschlagen: ${error.response.data.message}`);
    }
  };

  return (
    <React.Fragment>
      <Row style={{ backgroundColor: "#9dcedf" }}>
        <Col xs={20} sm={20} md={20} lg={20} xl={20} xxl={20}>
          <Flex align='center'>
            <Space style={{ padding: 25 }}>
              {data?.icon}
              <h6>{data?.title}</h6>
            </Space>
          </Flex>
        </Col>
        <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
          <Flex justify="end" align='center'>
            <Space style={{ padding: 15 }}>
              <Button size='small' type='' icon={<SettingsOutlinedIcon />} href="https://www.swimgood-schwimmschule.de" />
              <Button size='small' type='' icon={<LogoutOutlinedIcon />} onClick={handleLogout} />
            </Space>
          </Flex>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Header;