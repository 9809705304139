import React, { useState } from "react";
import { Flex, Tag, Space, List } from 'antd';
import { EditButton, DeleteButton, ModalDisplay, AvailableForm } from "../..";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export const AvailableItem = ({ ghost, editable, item, refresh, mainApi }) => {
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/available/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item style={{ padding: 4 }} >
            <Flex justify="space-between">
                <Space><CalendarMonthOutlinedIcon />
                    {item?.day + " - " + showTime(item?.start) + " Uhr - " + showTime(item?.end) + " Uhr"}
                </Space>
                <Space>
                    {!editable ? "" : <DeleteButton handleDelete={() => handleDelete()} />}
                </Space>
            </Flex>
        </List.Item >
    )
};

function showTime(time) {
    const [h, min] = time.split(':')
    return (h + ":" + min)
}
