import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip } from 'antd';
import { CourseTypeSelect, QuillEditor, SelectWithData } from '../..';
//Icons
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export const AwardForm = ({ title, courseType, isSuccess, uuid, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])


    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) { getData() }
        if (courseType) { form.setFieldValue("courseType", courseType) }
    }, []);


    const getData = async () => {
        let response;
        try {
            setLoading(true);
            response = await mainApi.get(`/award/one/${uuid}`);
            const award = {
                title: response?.data?.award?.awardAccessible?.informations[0]?.title,
                subtitle: response?.data?.award?.awardAccessible?.informations[0]?.subtitle,
                text: response?.data?.award?.awardAccessible?.informations[0]?.text,
                status: response?.data?.award?.awardStatus?.statusValue,
                courseType: response?.data?.award?.courseTypeId,
            }
            form.setFieldsValue(award);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
            isSuccess()
        } finally {
            setLoading(false);
        }
    };

    const setEditorValueInForm = (value) => {
        form.setFieldsValue({
            text: value,
        });
    };

    const onFinish = async (values) => {
        let response;
        values.userId = user?.uuid;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/award/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/award`, values);
            }
            isSuccess(response?.data?.award);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="AwardForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Abzeichen bearbeiten' : 'Abzeichen erstellen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><VisibilityOutlinedIcon /> Darstellung</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Abzeichens">
                                <Form.Item
                                    hasFeedback
                                    name="title"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Titel angeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Title ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Kürzel des Abzeichnes">
                                <Form.Item
                                    hasFeedback
                                    name="subtitle"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Kürzel angeben!',
                                    },
                                    {
                                        max: 100,
                                        message: 'Der Kürzel ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Kürzel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Bazeichens">
                                <Form.Item
                                    hasFeedback
                                    name="text"
                                >
                                    <QuillEditor placeholder={"Beschreibung"} defaultValue={form.getFieldValue("text")} onChange={setEditorValueInForm} />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        {courseType ? "" : <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <CourseTypeSelect mainApi={mainApi} />
                        </Col>}
                        <Col xs={12} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Wie die Kursart im System angezeigt werden?"}
                                name="status"
                                data={[
                                    { value: "ACTIVE", label: "Aktiv" },
                                    { value: "INTERNAL", label: "Intern" },
                                ]}
                            />

                        </Col>
                        <Col xs={12} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};