import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation, Navigate } from "react-router-dom";
import { Spin, Watermark } from "antd";
import { AdminApi, CustomApi, SystemApi } from './urlConfig';
import './style.scss'
import './App.css';

//Routes
import Dashboard from './pages/dashborad/DashboardPage'
import { CourseTypePage } from './pages/courseType/CourseTypePage'
import { OneCourseTypePage } from './pages/courseType/OneCourseTypePage'
import Login from './pages/start/LogInPage';
import SuccessPage from './pages/resultPages/SuccessPage';
import ErrorPage from './pages/resultPages/ErrorPage';
import Test from './pages/location2/OneLocationPage';
import { LocationPage } from './pages/location/LocationPage';
import { OneLocationPage } from './pages/location/OneLocationPage';
import { EmployeeNavigationPage } from './pages/employees/EmployeeNavigationPage';
import { EmployeePage } from './pages/employees/EmployeePage';
import { CoachPage } from './pages/employees/CoachPage';
import { UserPage } from './pages/employees/UserPage';
import { OneEmployeePage } from './pages/employees/OneEmployeePage';
import { OneCoachPage } from './pages/employees/OneCoachPage';
import { OneContactPage } from './pages/location/OneContactPage';
import { OneUnitPage } from './pages/location/OneUnitPage';
import { CoursePage } from './pages/course/CoursePage';
import { OneCoursePage } from './pages/course/OneCoursePage';
import { ClientNavigationPage } from './pages/clients/ClientNavigationPage';
import { CustomerPage } from './pages/customer/CustomerPage';
import { OneCustomerPage } from './pages/customer/OneCustomerPage';
import { ParticipantPage } from './pages/participant/ParticipantPage';
import { OneParticipantPage } from './pages/participant/OneParticipantPage';
import { ParticipationPage } from './pages/participation/ParticipationPage';
import { OneParticipationPage } from './pages/participation/OneParticipationPage';
import { BillPage } from './pages/bill/BillPage';

export function App() {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  const checkToken = async () => {
    try {
      const sys = await SystemApi.get(`/verify`);
      const emp = await AdminApi.get(`/verify`);
      const cust = await CustomApi.get(`/verify`);
      if (sys.status != 200 || emp.status != 200 || cust.status != 200) {
        navigate("/")
      }
    } catch (error) {
      navigate("/")
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    checkToken();
    const interval = setInterval(() => {
      checkToken();
    }, 1000 * 60 * 5); //2 min
    return () => clearInterval(interval);
  }, [location.pathname]);

  return (
    <React.Fragment>
      <Watermark content={""} gap={[500, 500]}>
        <Spin spinning={loading}>
          {loading ? "" :
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/success" element={< SuccessPage />} />
              <Route path="/error" element={<ErrorPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/courseType" element={<CourseTypePage />} />
              <Route path="/courseType/:uuid" element={<OneCourseTypePage />} />
              <Route path="/test/:uuid" element={<Test />} />
              <Route path="/location/:uuid" element={<OneLocationPage />} />
              <Route path="/contact/:uuid" element={<OneContactPage />} />
              <Route path="/unit/:uuid" element={<OneUnitPage />} />
              <Route path="/location" element={<LocationPage />} />
              <Route path="/employees" element={<EmployeeNavigationPage />} />
              <Route path="/clients" element={<ClientNavigationPage />} />
              <Route path="/employee" element={<EmployeePage />} />
              <Route path="/employee/:uuid" element={<OneEmployeePage />} />
              <Route path="/coach" element={<CoachPage />} />
              <Route path="/coach/:employeeId" element={<OneCoachPage />} />
              <Route path="/user" element={<UserPage />} />
              <Route path="/course" element={<CoursePage />} />
              <Route path="/course/:uuid" element={<OneCoursePage />} />
              <Route path="/customer" element={<CustomerPage />} />
              <Route path="/customer/:uuid" element={<OneCustomerPage />} />
              <Route path="/participant" element={<ParticipantPage />} />
              <Route path="/participant/:uuid" element={<OneParticipantPage />} />
              <Route path="/participation" element={<ParticipationPage />} />
              <Route path="/bill" element={<BillPage />} />
              <Route path="/participation/:uuid" element={<OneParticipationPage />} />


              <Route path="*" element={<Navigate to="/404" />} />
              <Route path="/404" element={<ErrorPage data={{ title: "Seite nicht gefunden", message: " ", error: "404 Seite nicht gefunden", primaryButton: { link: "/", label: "Startseite" } }} />} />
            </Routes>
          }
        </Spin>
      </Watermark>
    </React.Fragment>
  );
};

export default App;