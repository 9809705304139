import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import { Space, Card, Row, Col, Divider, Table, Tag, Badge } from 'antd';
import { CreateButton, ErrorDisplay, SearchFilter, ListDisplay, ModalDisplay, UnitItem, UnitForm, getFilter, ContactItem, ContactForm, CoachItem, CoachForm, CustomerForm, CustomerItem, ParticipationForm, ParticipationItemParticipant, ParticipationItemCourse, MoreButton } from "../..";
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';

export const ParticipationCourseList = ({ ghost, courseIds = "", participantIds = "", editable, card, mainApi, custApi, pagination, filterText, filterStartDate, filterEndDate, filterStatus = "ACTIVE", displayParticipantMode }) => {
    const [data, setData] = useState([]);
    const [course, setCourse] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const navigate = useNavigate();

    const getData = async (search = "", status = getFilter(filterStatus), start = getFilter(filterStartDate), end = getFilter(filterEndDate),) => {
        try {
            const response = await custApi.get(`/participation/all?page=${currentPage}&limit=${pageSize}&search=${search}&status=${status}&courseIds=${courseIds}&participantIds=${participantIds}&startDate=${start}&endDate=${end}`);
            setData(response.data);
        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };
    const getCourse = async () => {
        try {
            const response = await mainApi.get(`/course/one/${courseIds}`);
            let data = response.data.course;
            data.courseDates = data.courseDates.sort((a, b) => new Date(a.time.publicStart) - new Date(b.time.publicStart))
            setCourse(data);

        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true);
    };

    const handlePageSizeChange = (size) => {
        setCurrentPage(1);
        setPageSize(size);
        setRefresh(true);
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData()
        getCourse()
        setRefresh(false)
    }, [open, refresh]);

    const dates = course?.courseDates?.map((data, index) => ((
        <Col>
            <Tag>{new Date(data.time.publicStart).toLocaleString('de-DE', { day: '2-digit', month: '2-digit' })}</Tag>
        </Col>
    )))

    const columns = [
        { title: 'Nr.', dataIndex: 'index', rowScope: 'row', align: "center", width: 1, render: (text) => <Tag color="gray">{text}</Tag> },
        { title: 'Name', dataIndex: 'name', key: 'name', render: (text) => <a>{text}</a> },
        { title: 'Age', dataIndex: 'age', key: 'age', },
        { title: 'Status', dataIndex: 'status', render: (text) => <Tag color="green">{text}</Tag> },
        { title: 'Link', dataIndex: 'uuid', render: (text) => <MoreButton text="mehr" onClick={() => navigate(`participant/${text}`)}></MoreButton> },
    ];

    const entry = data?.participation?.map((data, index) => ({
        index: index + 1 + ".",
        age: data?.participant?.person.sex,
        name: data?.participant?.person?.name?.firstname + ", " + data?.participant?.person?.name?.lastname,
        status: data?.participationStatus?.statusValue,
        uuid: data?.participantId
    }));

    const result = (
        <Row Row gutter={[16, 16]} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                <ErrorDisplay error={error} loading={loading}>
                    <Table
                        pagination={false}
                        title={() => <Space><PoolOutlinedIcon />Kursübersicht</Space>}
                        bordered
                        size="small"
                        columns={columns} dataSource={entry} />
                    <ModalDisplay
                        onCancel={() => { setOpen(false) }}
                        open={open}
                        content={
                            <ParticipationForm
                                custApi={custApi}
                                isSuccess={() => {
                                    setOpen(false)
                                    setRefresh(true)
                                }}
                            />}
                    />
                </ErrorDisplay>
            </Col >
        </Row >
    );



    return (<>
        {card ? <Card size="small" loading={loading} > {result} </Card>
            :
            result}
    </>);
};