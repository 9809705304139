import React, { useEffect, useState } from "react";
import { Descriptions, Row, Col, Divider, Space } from 'antd';
import { CoachForm, CreateButton, EditButton, ErrorDisplay, ManagerForm, ModalDisplay, MoreButton } from "../..";
import { useNavigate } from "react-router-dom";

//Icons
import TocOutlinedIcon from '@mui/icons-material/TocOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';

export const ManagerData = ({ editable, employeeId, empApi }) => {
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        getData();
    }, [open, refresh]);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await empApi.get(`/manager/one/${employeeId}`);
            setData(response.data.manager)
        } catch (error) {
            setError("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const items = [
        {
            key: '1',
            label: (<> <VpnKeyOutlinedIcon fontSize="small" /> Zugänge: </>),
            children: (<Space direction="vertical">
                {(!data?.service && !data?.location && !data?.god && !data?.personal) ? "Keine Zugänge" : ""}
                {!data?.service ? "" : "Kunden und Kursbetriebs Manager"}
                {!data?.location ? "" : "Standort Manager "}
                {!data?.personal ? "" : "Personal Manager"}
                {!data?.god ? "" : "Manager"}
            </Space>),
            span: 24,
        },
    ]

    return (
        <ErrorDisplay error={error} loading={loading}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {(!data || data?.length === 0) ?
                        !editable ? "" :
                            <Divider orientation='left' orientationMargin="0">
                                <Space>
                                    <PsychologyOutlinedIcon /> Manager:
                                    <CreateButton text="Hinzufügen" onClick={() => setOpen(true)} />
                                </Space>
                            </Divider>
                        :
                        <>
                            <Divider orientation='left' orientationMargin="0">
                                <Space>
                                    <PsychologyOutlinedIcon /> Manager:
                                    {!editable ? "" : <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />}
                                    {/* <MoreButton text={"mehr"} onClick={() => navigate(`/manager/${data?.uuid}`)} /> */}
                                </Space>
                            </Divider>
                            <Descriptions size="small" bordered layout="vertical" items={items} />
                        </>}
                </Col>
            </Row>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<ManagerForm
                    employeeId={employeeId}
                    empApi={empApi}
                    uuid={!data ? "" : employeeId}
                    isSuccess={() => {
                        setOpen(false)
                        setRefresh(true)
                    }}
                />} />
        </ErrorDisplay>
    );
};