import React, { useEffect, useState } from 'react';
import { Form, Input,  Button, Spin, Space, message, Row, Col, Divider, Tooltip } from 'antd';
import { QuillEditor, AgeSelect, SelectWithArray, SelectWithData } from '../..';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import GroupOutlined from '@mui/icons-material/GroupOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

export const CourseTypeForm = ({ title, isSuccess, uuid, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) {setUser(storedUser)}
        if (uuid) {
            getCourseType();
        }
    }, []);

    const getCourseType = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/courseType/one/${uuid}`);
            const courseTypeData = {
                places: response?.data?.courseType?.places,
                minAgeYear: response?.data?.courseType?.ageRange?.minAge?.year,
                minAgeMonth: response?.data?.courseType?.ageRange?.minAge?.month,
                maxAgeYear: response?.data?.courseType?.ageRange?.maxAge?.year,
                maxAgeMonth: response?.data?.courseType?.ageRange?.maxAge?.month,
                status: response?.data?.courseType?.courseTypeStatus?.statusValue,
                lessonTime: response?.data?.courseType?.duration?.lessonTime,
                overallTime: response?.data?.courseType?.duration?.overallTime,
                title: response?.data?.courseType?.courseTypeAccessible?.informations[0]?.title,
                subtitle: response?.data?.courseType?.courseTypeAccessible?.informations[0]?.subtitle,
                text: response?.data?.courseType?.courseTypeAccessible?.informations[0]?.text,
            }
            form.setFieldsValue(courseTypeData);
        } catch (error) {
            message.error(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
        } finally {
            setLoading(false);
        }
    };

    const setEditorValueInForm = (value) => {
        form.setFieldsValue({
            text: value,
        });
    };

    const onFinish = async (values) => {
        values.userId = user?.uuid;
        try {
            setLoading(true);
            if (uuid) {
                await mainApi.patch(`/courseType/update/${uuid}`, values);
            } else {
                await mainApi.post(`/courseType`, values);
            }
            isSuccess();
        } catch (error) {
            message.error(`Fehlgeschlagen: ${(error?.response?.data?.message)}`);
            message.error(`Fehlgeschlagen: ${((error))}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="CourseTypeForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical" a
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Kursart ändern' : 'Kursart anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><VisibilityOutlinedIcon /> Darstellung</Divider>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="title"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Titel angeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Title ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Untertitel des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="subtitle"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Untertitel angeben!',
                                    },
                                    {
                                        max: 100,
                                        message: 'Der Untertitel ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Untertitel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Kurses">
                                <Form.Item
                                    hasFeedback
                                    name="text"
                                >
                                    <QuillEditor placeholder={"Kursbeschreibung"} defaultValue={form.getFieldValue("text")} onChange={setEditorValueInForm} />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        {/*Weitere Infos*/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><AddCircleOutlineOutlinedIcon /> Daten </Divider>
                        </Col>

                        {/*MinAge*/}
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                            <AgeSelect
                                maxRange={100}
                                label="Mindestjahr"
                                tooltip={"Mindestaltersgrenze für die Teilnehmer"}
                                name="minAgeYear"
                                icon={<IndeterminateCheckBoxOutlinedIcon fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                            <AgeSelect
                                maxRange={11}
                                label="Mindestmonat"
                                tooltip={"Mindestaltersgrenze für die Teilnehmer"}
                                name="minAgeMonth"
                                icon={<IndeterminateCheckBoxOutlinedIcon fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>


                        {/*MaxAge*/}
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                            <AgeSelect
                                maxRange={100}
                                label="Maximaljahr"
                                tooltip={"Maximalaltersgrenze für die Teilnehmer"}
                                name="maxAgeYear"
                                icon={<AddBoxOutlinedIcon fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6} >
                            <AgeSelect
                                maxRange={11}
                                label="Maximalmonat"
                                tooltip={"Maximalaltersgrenze für die Teilnehmer"}
                                name="maxAgeMonth"
                                icon={<AddBoxOutlinedIcon fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <SelectWithArray
                                start={0}
                                end={50}
                                label="Teilnehmer"
                                tooltip={"Teilnehmeranzahl pro Trainer"}
                                name="places"
                                icon={<GroupOutlined fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <SelectWithArray
                                start={0}
                                end={120}
                                steps={5}
                                label="Min. Kurszeit"
                                tooltip={"Kurszeit von einer Einheit in Minuten"}
                                name="lessonTime"
                                icon={<AccessTimeOutlinedIcon fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <SelectWithArray
                                start={0}
                                end={120}
                                steps={5}
                                label="Min. Gesamtzeit"
                                tooltip={"Gesamtzeit von einer Einheit in Minuten"}
                                name="overallTime"
                                icon={<AccessTimeOutlinedIcon fontSize='small' style={{ marginRight: '8px' }} />}
                            />
                        </Col>


                        <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Wie die Kursart im System angezeigt werden?"}
                                name="status"
                                data={[
                                    { value: "ACTIVE", label: "Sichtbar für alle" },
                                    { value: "INTERNAL", label: "Nur Intern für Verwaltung sichtbar" },
                                    { value: "SOON", label: "Ankündigen: für Trainer sichtbar" },
                                ]}
                            />

                        </Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};