import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip } from 'antd';
import { QuillEditor, SelectWithData } from '../..';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

export const CommunicationForm = ({ title, isSuccess, uuid, contactId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/communication/one/${uuid}`);
            const communication = {
                reason: response?.data?.communication?.reason,
                content: response?.data?.communication?.content,
                status: response?.data?.communication?.communicationStatus?.statusValue.toUpperCase(),
            }
            form.setFieldsValue(communication);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const setEditorValueInForm = (value) => {
        form.setFieldsValue({
            content: value,
        });
    };

    const onFinish = async (values) => {
        let response;
        values.userId = user?.uuid;
        values.contactId = contactId;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/communication/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/communication`, values);
            }
            isSuccess(response.data.communication);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="CommunicationForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical" a
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Kommunikation ändern' : 'Kommunikation anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><VisibilityOutlinedIcon /> Darstellung</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Grund der Kommunikation">
                                <Form.Item
                                    hasFeedback
                                    name="reason"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Grund angeben!',
                                    },
                                    {
                                        max: 255,
                                        message: 'Der Grund ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Grund" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Inhalt der Kommunikation">
                                <Form.Item
                                    hasFeedback
                                    name="content"
                                >
                                    <QuillEditor placeholder={"Inhalt"} defaultValue={form.getFieldValue("content")} onChange={setEditorValueInForm} />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Was ist der Status der Kommunikation?"}
                                name="status"
                                data={[
                                    { value: "TODO", label: "Muss von uns bearbeitet werden." },
                                    { value: "OPEN", label: "Eine Antowrt ist austehend" },
                                    { value: "FINISHED", label: "Abgeschlossen" },
                                ]}
                            />

                        </Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};