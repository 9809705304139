// Sidebar.js
import React, { useState, useEffect } from "react";
import { Button, Col, Divider, Flex, Row, Space, Card } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../layout/Layout";
//MyCourseIcons
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { SystemApi } from "../../urlConfig";
import { UnitData, UnitTimeList, EditButton, ErrorDisplay, ModalDisplay, UnitForm, AccessibleDisplay } from "../../package";

export const OneUnitPage = ({ layout }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { uuid } = useParams();
  const [refresh, setRefresh] = useState(false);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({});
  const headerData = {
    title: `${data?.unitAccessible?.informations[0]?.title + " - " + data?.unitAccessible?.informations[0]?.subtitle}`,
    icon: <ListAltOutlinedIcon />,
  }

  const checkTokenAndFetchData = async () => {
    const manager = JSON.parse(localStorage.getItem("manager"))
    setEdit(manager.god)
    setLoading(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await SystemApi.get(`/unit/one/${uuid}`);
      setData(response.data.unit);
    } catch (error) {
      setError(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    checkTokenAndFetchData();
    const fetchData = async () => {
      await getData();
    };
    fetchData();
  }, [open, refresh]);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay error={error} loading={loading}>
          <Row gutter={[0, 0]} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Flex justify="space-between">
                <Button type="primary" size="small" icon={<ArrowBackOutlinedIcon fontSize="small" />} onClick={() => navigate(`/location/${data?.locationId}`)} >Zurück</Button>
                {!edit ? "" : <EditButton text="Raum Bearbeiten" onClick={() => setOpen(true)} />}
              </Flex>
              <Divider style={{ margin: 10 }}></Divider>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Space split direction="vertical" style={{ width: "100%" }}>
                    <AccessibleDisplay mainApi={SystemApi} information accessibleId={data?.accessibleId} editable={edit} />
                    <Card>
                      <UnitData data={data} />
                    </Card>
                  </Space>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Space split direction="vertical" style={{ width: "100%" }}>
                    {/* <AccessibleDisplay mainApi={SystemApi} description accessibleId={data?.accessibleId} editable={edit} /> */}
                    <UnitTimeList editable={edit} mainApi={SystemApi} unitId={uuid} />
                  </Space>
                </Col>
              </Row>
            </Col>
          </Row>
          <ModalDisplay
            onCancel={() => { setOpen(false) }}
            open={open}
            content={
              <UnitForm
                mainApi={SystemApi}
                uuid={data?.uuid}
                isSuccess={() => {
                  setOpen(false)
                  setRefresh(true)
                }}
              />}
          />
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};