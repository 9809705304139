import React, { useState } from "react";
import { Flex, Tag, Space, List, Collapse, Col, Row } from 'antd';
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton, ModalDisplay, MoreButton, UnitForm, UnitData, UnitTimeList } from "../..";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const UnitItem = ({ ghost, editable, item, refresh, mainApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        await mainApi.patch(`/unit/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <List.Item>
            <Row gutter={[8, 16]} justify="space-between">
                <Col flex>
                    {item?.unitAccessible?.informations[0]?.title + " - " + item?.unitAccessible?.informations[0]?.subtitle}
                </Col>
                <Col flex>
                    <Space>
                        <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.unitStatus?.msg)}</Tag>
                        <MoreButton text="mehr" onClick={() => navigate(`/unit/${item?.uuid}`)} />
                    </Space>
                </Col>
            </Row>
            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<UnitForm
                    mainApi={mainApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item>
    )
};

