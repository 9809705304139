import React, { useEffect, useState } from 'react';
import { Form, Upload, Button, message, Space, Spin, Row, Col, Divider, Tooltip, Input } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { SelectWithData } from '../../../components/form/parts/SelectWithData';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';

export const DocumentForm = ({ title, isSuccess, accessibleId, uuid, type, mainApi, fileApi }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState([])

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getImage();
        }
    }, []);

    const getImage = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/document/one/${uuid}`);
            const document = {
                title: response?.data?.document?.title,
                subtitle: response?.data?.document?.subtitle,
                type: response?.data?.document?.type,
                access: response?.data?.document?.access,
            }
            form.setFieldsValue(document);
        } catch (error) {
            message.error('Fehler beim Laden der Daten: ' + error?.response?.data?.message && error?.message);
            isSuccess()
        } finally {
            setLoading(false);
        }
    };


    const onFinish = async (values) => {
        if (type) { values.type = type }
        values.accessibleId = accessibleId;
        values.userId = user?.uuid;
        try {
            setLoading(true);
            if (uuid) {
                await mainApi.patch(`/document/update/${uuid}`, values);
            } else {
                const formData = new FormData();
                formData.append('title', values.title);
                formData.append('subtitle', values.subtitle);
                formData.append('type', values.type);
                formData.append('accessibleId', values.accessibleId);
                formData.append('userId', values.userId);
                formData.append('document', values.document[0].originFileObj);

                await fileApi.post(`/document`, formData);
            }
            isSuccess();
        } catch (error) {
            message.error('Fehler beim Hochladen des Dokuments' + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
            form.resetFields();
        }
    };

    const beforeUpload = (file) => {
        return false;
    };

    return (
        <Form
            name="document-form"
            requiredMark={false}
            form={form}
            onFinish={onFinish}
            layout="vertical"
            validateTrigger="onChange"
            style={{ width: "100%" }}
        >
            <Space direction="vertical">
                <h3>{uuid ? 'Documentenbeschreibung ändern' : 'Dokument hochladen'}</h3>
            </Space>
            <Spin spinning={false} size="large" loading={loading}>
                <Row gutter={[16, 0]}>
                    {/**/}
                    <Col span={24}>
                        <Divider orientation='left' orientationMargin="0"><UploadOutlined /> Informationen</Divider>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel des Documents">
                            <Form.Item
                                hasFeedback
                                name="title"
                                rules={[{
                                    required: true,
                                    min: 1,
                                    message: 'Bitte einen Titel angeben!',
                                },
                                {
                                    max: 50,
                                    message: 'Der Title ist zu Lang!',
                                },
                                ]}>
                                <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Titel" />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Beschreibung des Documents">
                            <Form.Item
                                hasFeedback
                                name="subtitle"
                                rules={[{
                                    required: true,
                                    min: 1,
                                    message: 'Bitte eine Beschreibung angeben!',
                                },
                                {
                                    max: 100,
                                    message: 'Der Beschreibung ist zu Lang!',
                                },
                                ]}>
                                <Input prefix={<TitleOutlinedIcon fontSize='small' />} placeholder="Beschreibung" />
                            </Form.Item>
                        </Tooltip>
                    </Col>
                    {uuid ? "" :
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item
                                name="document"
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                rules={[{ required: true, message: 'Bitte lade ein Dokument hoch!' }]}
                            >
                                <Upload
                                    name="document"
                                    beforeUpload={beforeUpload}
                                    maxCount={1}
                                    accept='.xlsx,.xls,.pdf,.doc,.docx'
                                    listType="picture"
                                >
                                    <Button icon={<UploadOutlined />} loading={loading}>
                                        Dokument hochladen
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </Col>}
                    {type ? "" :
                        <Col xs={24} sm={12} md={8} lg={9} xl={9} >
                            <SelectWithData
                                label="Typ"
                                tooltip={"Wie soll das Document im System angezeigt werden?"}
                                name="type"
                                data={[
                                    { value: "info", label: "Zusatzinformation" },
                                    { value: "main", label: "Hauptdokument" },
                                    { value: "other", label: "Weitere Dokumente" },
                                ]}
                            />
                        </Col>
                    }
                    <Col xs={24} sm={12} md={type ? 16 : 8} lg={type ? 18 : 9} xl={type ? 18 : 9} >
                        <SelectWithData
                            label="Status"
                            tooltip={"Wie soll das Bild im System angezeigt werden?"}
                            name="access"
                            data={[
                                { value: "public", label: "Öffentlich" },
                                { value: "intern", label: "Intern" },
                            ]}
                        />

                    </Col>
                    <Col xs={8} sm={type ? 0 : 12} md={0} lg={0} xl={0} ></Col>
                    <Col xs={16} sm={12} md={8} lg={6} xl={6} >
                        <Form.Item >
                            <Button type="primary" htmlType="submit" loading={loading} block>
                                {title ? title : uuid ? "Speichern" : 'Erstellen'}                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Spin>
        </Form>
    );
};